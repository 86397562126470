@import "../../styles/vars.scss";

.screen-wrapper.services {
  background-image: url("../../assets/images/services-background.webp");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;

  .screen-inner-wrapper {
    width: 100%;

    .screen-header-wrapper {
      padding: 0 2vw;
      // width: 100%;

      h1 {
        color: $text-grey;
        font-size: 60px;
        text-align: left;
      }

      p {
        text-align: left;
      }

      .flex {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &.padding {
          padding-top: 20px;
        }

        span {
          @include regularHeading;
          cursor: pointer;
        }

        img {
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }

    .screen-main-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      gap: 15px;
      padding: 0 2vw;
      margin-bottom: 60px;

      &.mobile {
        display: none;
      }

      .cards-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        // flex-wrap: wrap;
        gap: 15px;
        height: 43vh;

        &.mobile {
          display: none;
        }

        img {
          justify-content: flex-start;
        }

        .section-wrapper.card {
          h2 {
            color: $main-orange;
          }
        }
      }
    }

    .section-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      &.text {
        .title-wrapper {
          width: 100%;

          h1 {
            margin-bottom: 0;
            text-transform: uppercase;
          }
          h2 {
            color: $main-orange;
          }
        }
      }

      &.card {
        text-align: center;
        align-items: center;
        justify-content: flex-start;
        @include sectionBase;
        box-shadow: none;
        border: 1px solid rgba(195, 191, 191, 0.562);
        z-index: 10;
        opacity: 1;
        margin: 0;
        padding: 45px 55px;
        // height: 400px;
        transition: 1s;
        min-height: fit-content;
        align-self: stretch;

        &.start {
          align-self: flex-start;
        }
        &.end {
          align-self: flex-end;
        }
        &.margin {
          margin-bottom: 90px;
        }
        &.hidden {
          h2,
          p,
          img {
            display: none;
            transition: 1s;
          }
        }
        &:hover {
          transition: 1s;
          img,
          h2,
          p {
            display: hidden;
          }
        }

        h2 {
          @include regularHeading;
          font-size: 20px;
          font-size: 18px;
          margin-bottom: 0;
        }

        .flex-col {
          display: flex;
          flex-direction: column;
          align-self: flex-start;
        }

        .section-inner-wrapper {
          // display: flex;
          align-self: center;
          margin-top: 30px;
        }

        .icon {
          width: 55px;
          height: auto;
        }

        .link {
          display: flex;
          align-items: center;
          cursor: pointer;
          // @include smallHeading;
          @include tableText;
          color: $main-orange;
        }
      }

      .flex {
        display: flex;
        align-items: center;
        width: 100%;

        &.col {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 10px;
          transition: 0.5s;
        }
        &.start {
          align-items: flex-start;
        }
        &.hidden {
          display: none;
          transition: 1s;
        }
        &:hovered {
          display: flex;
          justify-content: center;
          transition: 1s;
        }

        h5 {
          font-size: 18px;
          font-family: $fonts-light;
          margin: 0;
          text-align: left;
        }

        .line {
          min-width: 4px;
          max-width: 4px;
          height: 30px;
          background-color: $black-text;
          border-radius: 5px;
          margin-right: 10px;

          &.orange {
            background-color: $main-orange;
          }
        }
      }

      .primary-bttn {
        @include primaryBttn;
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .screen-wrapper.services {
    .screen-inner-wrapper {
      .screen-main-wrapper {
        .cards-wrapper {
          height: 55vh;
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .screen-wrapper.services {
    background-image: none;

    .screen-inner-wrapper {
      .screen-header-wrapper {
        padding: 0;
        padding: 0 20px;

        span {
          font-size: 16px;
        }

        .section-wrapper {
          &.text {
            .title-wrapper {
              h1 {
                font-size: 26px;
                color: $light-grey;
                font-family: $fonts-bold;
              }

              h2 {
                color: $main-orange;
                font-size: 18px;
                font-family: $fonts-regular;
              }

              .line {
                background-color: $main-orange;
              }
            }
          }
        }
      }

      .screen-main-wrapper {
        padding: 0 20px;

        &.desktop {
          display: none;
        }
        &.mobile {
          display: flex;

          .primary-bttn {
            @include primaryBttn;
            width: 100%;
            font-size: 14px;
            font-family: $fonts-bold;
          }
        }

        .cards-wrapper {
          flex-wrap: wrap;
          height: fit-content;

          .section-wrapper.card {
            width: 100%;
            align-items: flex-start;
            text-align: left;
            padding: 20px 25px;
            height: unset;

            .section-inner-wrapper {
              margin-top: 0;
              text-align: left;
              width: 100%;

              .icon {
                width: 54px;
                height: 54px;
              }
            }

            .flex {
              h5 {
                all: initial;
                font-size: 16px;
                font-family: $fonts-light;
              }

              .line {
                min-width: 4px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 2100px) {
  .screen-wrapper.services {
    .screen-inner-wrapper {
      height: calc(100vh - 296px);

      .screen-main-wrapper.desktop {
        margin-bottom: 80px;
        // min-height: 430px;

        .cards-wrapper {
          height: 26vh;
        }
      }
    }
  }
}
