@import "../../styles/vars.scss";

.screen-wrapper.manufacturers {
  background-image: url("../../assets/images/manufacturers-section-background.webp");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;

  .screen-inner-wrapper {
    width: 100%;

    .screen-header-wrapper {
      padding: 0 2vw;
      // width: 100%;

      h1,
      .title-section {
        color: $text-grey;
        font-size: 60px;
        text-align: left;
      }

      .flex {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        h2 {
          font-family: $fonts-regular;
        }

        &.padding {
          padding-top: 20px;
        }

        span {
          @include regularHeading;
          cursor: pointer;
        }

        img {
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }

    .screen-main-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      gap: 15px;
      padding: 0 2vw;
      margin-bottom: 60px;

      .cards-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // flex-wrap: wrap;
        gap: 15px;

        img {
          justify-content: flex-start;
        }
      }
    }

    .section-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      &.text {
        .title-wrapper {
          width: 100%;

          h1,
          .title-section {
            margin-bottom: 0;
          }
          h2 {
            color: $main-orange;
          }
        }
      }

      &.card {
        text-align: center;
        align-items: center;
        justify-content: flex-start;
        @include sectionBase;
        box-shadow: none;
        border: 1px solid rgba(195, 191, 191, 0.562);
        z-index: 10;
        opacity: 1;
        margin: 0;
        padding: 45px 55px;
        padding-top: 65px;
        height: 300px;
        transition: 1s;
        // flex-basis: 250px;

        &.start {
          align-self: flex-start;
        }
        &.end {
          align-self: flex-end;
        }
        &.margin {
          margin-bottom: 90px;
        }

        p {
          @include regularHeading;
          font-size: 20px;
          font-size: 18px;
          margin-bottom: 0;
        }

        .icon {
          width: auto;
          height: 36px;
        }

        .link {
          display: flex;
          align-items: center;
          cursor: pointer;
          // @include smallHeading;
          @include tableText;
          color: $main-orange;
        }
      }

      &.bottom {
        text-align: left;
        width: 100%;
        align-items: flex-start;
        @include sectionBase;
        padding: 50px 30px;

        .title-wrapper {
          h1,
          .title-section {
            color: $light-grey;
            font-size: 60px;
            text-transform: uppercase;
            font-size: $fonts-bold;
            font-weight: 900;
            margin: 0;
          }

          .flex {
            display: flex;
            align-items: center;

            h2 {
              @include mediumHeading;
              color: $main-orange;
              margin-top: 10px;
              margin-bottom: 15px;
            }

            .line {
              width: 7px;
              height: 30px;
              background-color: $main-orange;
              border-radius: 5px;
            }
          }
        }

        .cards-wrapper {
          align-items: stretch;

          .section-wrapper {
            border: 1px solid rgba(195, 191, 191, 0.562);
            padding: 15px 25px;
            align-items: center;
            border-radius: 10px;

            .card-image {
              width: 100%;
            }

            .card-text-wrapper {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              gap: 15px;
              width: 100%;
              flex: 1;

              h2 {
                @include boldHeading;
                font-size: 20px;
                text-transform: uppercase;
                text-align: left;
                width: 100%;
                margin-bottom: 0;
                margin-bottom: auto;
              }

              p {
                margin: 0;
                font-size: 18px;
                font-family: $fonts-light;
              }

              .card-content-wrapper {
                // margin-top: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 10px;
                height: 120px;
              }
            }
          }
        }
      }
      &.bottom-card {
        height: 420px;
      }

      .flex {
        display: flex;
        align-items: center;

        &.col {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 10px;
          transition: 0.5s;
        }
        &.hidden {
          display: none;
          transition: 1s;
        }
        &:hovered {
          display: flex;
          justify-content: center;
          transition: 1s;
        }

        h5 {
          font-size: 18px;
          font-family: $fonts-light;
          margin: 0;
          text-align: left;
        }

        .line {
          width: 7px;
          height: 30px;
          background-color: $black-text;
          border-radius: 5px;
          margin-right: 10px;

          &.orange {
            background-color: $main-orange;
          }
        }
      }

      .primary-bttn {
        @include primaryBttn;
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .screen-wrapper.manufacturers {
    .screen-inner-wrapper {
      .screen-header-wrapper {
        h2 {
          font-size: 22px;
        }
      }

      .screen-main-wrapper {
        padding: 0 20px;

        .cards-wrapper {
          flex-wrap: wrap;
          height: 100%;

          .section-wrapper {
            &.card {
              justify-content: center;
              height: unset;
              padding: 20px 25px;
              flex: 1;
              align-self: stretch;

              p {
                font-size: 16px;
              }

              .icon {
                height: 34px;
                width: auto;
              }
            }
          }
        }

        .section-wrapper.bottom {
          .cards-wrapper {
            .section-wrapper {
              &.bottom-card {
                justify-content: center;
                width: 48%;

                h2 {
                  font-size: 18px;
                  margin-bottom: 10px;
                }

                .card-text-wrapper {
                  flex: none;
                }

                .card-image {
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .screen-wrapper.manufacturers {
    background-image: none;

    .screen-inner-wrapper {
      .screen-header-wrapper {
        padding: 0;
        padding: 0 20px;

        span {
          font-size: 16px;
        }

        .section-wrapper {
          &.text {
            .title-wrapper {
              h1,
              .title-section {
                font-size: 26px;
                color: $light-grey;
              }

              h2 {
                color: $main-orange;
                font-size: 18px;
              }

              .line {
                background-color: $main-orange;
              }
            }
          }
        }
      }

      .screen-main-wrapper {
        padding: 0 20px;

        .cards-wrapper {
          flex-direction: column;
          width: 100%;
          .section-wrapper {
            &.card {
              justify-content: center;
              height: unset;
              padding: 20px 25px;

              p {
                font-size: 16px;
              }

              .icon {
                height: 34px;
                width: auto;
              }
            }
          }
        }

        .section-wrapper.bottom {
          box-shadow: none;
          padding: 50px 0;

          .title-wrapper {
            h1,
            .title-section {
              font-size: 26px;
              color: $light-grey;
            }

            h2 {
              color: $main-orange;
              font-size: 18px;
            }

            .line {
              background-color: $main-orange;
            }
          }

          .cards-wrapper {
            .section-wrapper {
              &.bottom-card {
                width: 100%;
                height: unset;
                padding: 0px;
                .card-image {
                  padding: 5px;
                  width: 100%;
                }
                .card-text-wrapper {
                  padding: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 2200px) {
  .screen-wrapper.manufacturers {
    .screen-inner-wrapper {
      .screen-main-wrapper {
        .section-wrapper.bottom {
          .cards-wrapper {
            height: 500px;
            .section-wrapper {
              &.bottom-card {
                height: 100%;
                align-self: stretch;
              }
            }
          }
        }
      }
    }
  }
}
