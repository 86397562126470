@import "../../styles/vars.scss";

.screen-wrapper.about-screen {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // padding: 0 2vw;
  // height: 80vh;

  .screen-inner-wrapper {
    margin-top: 80px;

    .main-section {
      &.top {
        @include sectionBase;
        display: flex;
        align-items: center;
        margin: 0 2vw 10vw 2vw;
        position: relative;
        padding-bottom: 10vw;
        .benefits-container {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          bottom: 0;
          left: 0;
          right: 0;
          transform: translateY(50%);
          > div {
            @include sectionBase;
            background-color: #fdf9f5;
            height: 16vw;
            width: 16vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            .icon-container {
              height: 50%;
              width: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px dashed #313131;
              border-radius: 6px;
              > img {
                width: 75%;
                height: 75%;
              }
            }
            .text-container {
              padding-top: 10%;
              height: 50%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
            p {
              text-align: center;
              color: $main-orange;
              font-family: $fonts-bold;
              text-transform: uppercase;
              margin: 0;
              // flex: 1;
              display: flex;
              align-items: center;
            }
            span {
              flex: 1;
              text-align: center;
              font-size: 14px;
              font-family: $fonts-regular;
              color: #313131;
              text-transform: uppercase;
            }
          }
        }
        .section-wrapper {
          &.left {
            text-align: left;
            width: 45%;

            .title-wrapper {
              h1,
              .title-section {
                color: $light-grey;
                font-size: 60px;
                text-transform: uppercase;
                font-size: $fonts-bold;
                font-weight: 900;
                margin-bottom: 0;
                margin-top: 10px;
                margin-left: 0;
              }

              .flex {
                display: flex;
                align-items: center;

                h2 {
                  @include mediumHeading;
                  color: $main-orange;
                  margin-top: 10px;
                  margin-bottom: 15px;
                  padding-left: 10px;
                }

                .line {
                  width: 7px;
                  height: 30px;
                  background-color: $main-orange;
                  border-radius: 5px;
                }
              }
            }

            .text-wrapper {
              @include regularHeading;
              margin-bottom: 60px;
            }
          }

          &.right {
            width: 55%;

            .text-wrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              h1,
              .text-title {
                margin: 0;
                font-size: 100px;
                font-family: $fonts-bold;
                color: $main-orange;
              }

              p {
                margin: 0;
                font-size: 30px;
                font-family: $fonts-medium;
              }
            }

            .graphic-wrapper {
              margin-top: 20px;
              img {
                width: 90%;
              }
            }
          }
        }
      }

      &.activities {
        background-image: url("../../assets/images/about-screen-blue-background.webp");
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: 50px;
        padding: 50px 0;
        min-height: 80vh;

        .title-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0 2vw;
          margin-bottom: 30px;

          h1,
          .title-section {
            color: $white;
            font-size: 60px;
            text-transform: uppercase;
            font-family: $fonts-bold;
            font-weight: 900;
            margin-bottom: 0;
            margin-top: 10px;
            margin-left: 0;
          }

          .flex {
            display: flex;
            align-items: center;

            h2 {
              @include mediumHeading;
              color: $white;
              margin-top: 10px;
              margin-bottom: 15px;
              padding-left: 10px;
            }

            .line {
              width: 7px;
              height: 30px;
              background-color: $white;
              border-radius: 5px;
            }
          }
        }

        .cards-wrapper {
          display: flex;
          align-items: stretch;
          justify-content: center;
          gap: 15px;
          padding: 0 2vw;
          margin-bottom: 20px;

          img {
            justify-content: flex-start;
          }

          .section-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            &.card {
              text-align: center;
              align-items: center;
              justify-content: flex-start;
              @include sectionBase;
              background-color: $medium-blue;
              box-shadow: none;
              border: 1px solid rgba(195, 191, 191, 0.562);
              z-index: 10;
              opacity: 1;
              margin: 0;
              padding: 45px 55px;
              height: 340px;
              transition: 1s;
              &.benefits {
                height: auto;
              }
              &.start {
                align-self: flex-start;
              }
              &.end {
                align-self: flex-end;
              }
              &.margin {
                margin-bottom: 90px;
              }

              h2 {
                @include regularLargeHeading;
                font-family: $fonts-bold;
                text-transform: uppercase;
                color: $white;
                font-size: 20px;
                font-size: 18px;
                margin-bottom: 0;
              }

              p {
                color: $white;
                font-size: 20px;
              }

              .icon {
                width: auto;
                height: 55px;
              }

              .link {
                display: flex;
                align-items: center;
                cursor: pointer;
                // @include smallHeading;
                @include tableText;
                color: $white;
              }
            }

            // .flex {
            //     display: flex;
            //     align-items: center;
            //     width: 100%;

            //     &.col {
            //         flex-direction: column;
            //         align-items: flex-start;
            //         justify-content: center;
            //         gap: 10px;
            //         transition: 0.5s;
            //     }
            //     &.start {
            //         align-items: flex-start;
            //     }
            //     &.hidden {
            //         display: none;
            //         transition: 1s;
            //     }
            //     &:hovered {
            //         display: flex;
            //         justify-content: center;
            //         transition: 1s;
            //     }

            //     h5 {
            //         font-size: 18px;
            //         font-family: $fonts-light;
            //         margin: 0;
            //         text-align: left;
            //     }

            //     .line {
            //         min-width: 4px;
            //         max-width: 4px;
            //         height: 30px;
            //         background-color: $black-text;
            //         border-radius: 5px;
            //         margin-right: 10px;

            //         &.orange {
            //             background-color: $main-orange;
            //         }
            //     }
            // }
          }
        }
        .benefits-container {
          display: flex;
          align-items: stretch;
          flex-wrap: wrap;
          gap: 15px;
          margin: 15px 0;
          p {
            border: 1px solid $main-orange;
            border-radius: 6px;
            flex: 1 0 33%;
            margin: 0;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            text-align: start;
          }
        }
        .team-wrapper {
          @include sectionBase;
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          margin: 0 2vw;
          display: flex;
          flex-direction: column;

          .title-wrapper {
            align-self: flex-start;

            h1,
            .title-section {
              color: $light-grey;
              font-size: 60px;
              text-transform: uppercase;
              font-size: $fonts-bold;
              font-weight: 900;
              margin-bottom: 0;
              margin-top: 10px;
              margin-left: 0;
            }

            .flex {
              display: flex;
              align-items: center;

              h2 {
                @include mediumHeading;
                color: $main-orange;
                font-size: 24px;
                margin-top: 10px;
                margin-bottom: 15px;
                padding-left: 10px;
              }

              .mobile {
                display: none;
              }

              .line {
                width: 7px;
                height: 30px;
                background-color: $main-orange;
                border-radius: 5px;
              }
            }
          }

          .section-wrapper {
            &.video-card {
              cursor: pointer;
              box-shadow: none;
              z-index: 10;
              opacity: 1;
              margin: 0;
              width: 100%;
              align-items: flex-start;
              padding: 50px 30px;
              border: 1px solid rgba(195, 191, 191, 0.562);
              padding: 15px 25px;
              align-items: center;
              border-radius: 10px;

              .card-image {
                width: 100%;
                border-radius: 10px;

                .ytp-pause-overlay {
                  display: none !important;
                }

                .ytp-chrome-top {
                  display: none !important;
                }
                .html5-video-player {
                  display: none !important;
                  .ytp-chrome-bottom {
                    .ytp-chrome-controls {
                      .ytp-chrome-top {
                        display: none !important;
                        &.ytp-show-cards-title {
                          display: none !important;
                        }
                      }
                    }
                  }
                }
              }

              iframe {
                &.ytp-chrome-top.ytp-show-cards-title {
                  display: none !important;
                }
              }

              .card-text-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                gap: 15px;
                width: 100%;
                flex: 1;

                h2 {
                  @include boldHeading;
                  font-size: 20px;
                  text-transform: uppercase;
                  text-align: center;
                  width: 100%;
                  margin-bottom: 0;
                  text-transform: uppercase;
                }

                p {
                  margin: 0;
                  font-size: 18px;
                  font-family: $fonts-light;
                  text-align: center;
                }
              }
            }
          }
        }
      }

      &.brands {
        // @include sectionBase;
        // display: flex;
        // align-items: center;
        margin-bottom: 40px;
        margin: 0 2vw;

        .title-wrapper {
          h1,
          .title-section {
            color: $light-grey;
            font-size: 60px;
            text-transform: uppercase;
            font-size: $fonts-bold;
            font-weight: 900;
            margin-bottom: 0;
            margin-top: 10px;
            margin-left: 0;
            text-align: left;
          }

          .flex {
            display: flex;
            align-items: center;

            h2 {
              @include mediumHeading;
              color: $main-orange;
              margin-top: 10px;
              margin-bottom: 15px;
              padding-left: 10px;
            }

            .line {
              width: 7px;
              height: 30px;
              background-color: $main-orange;
              border-radius: 5px;
            }
          }
        }

        .cards-wrapper {
          &.mobile {
            display: none;
          }

          .card-wrapper {
            display: flex !important;
            justify-content: center;
            align-items: center;
            width: 96%;
            height: 100%;
            flex-basis: 350px;
            transition: 0.5s;
            cursor: default;

            &:hover {
              border: 4px solid $main-orange;
              border-radius: 10px;
              transition: 0.5s;

              img {
                width: 95%;
              }
            }

            img {
              width: 100%;
              object-fit: cover;
              cursor: pointer;
            }
          }
        }

        .text-wrapper {
          @include regularHeading;
          margin-bottom: 60px;
          text-align: left;
        }
      }

      &.bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        padding: 0 2vw;
        margin: 80px 0;
        margin-top: 10px;
        gap: 30px;

        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 83vh;
          height: fit-content;
          background-repeat: no-repeat;
          background-size: 60%;

          &.products {
            background-image: url("../../assets/images/product_services.webp");
            background-position: left bottom;
            justify-content: flex-start;
            align-items: center;
            height: 45vh;
            width: 100%;

            .card {
              width: 45%;
              margin-right: 0;
            }
          }

          &.services {
            background-image: url("../../assets/images/services.webp");
            background-position: right bottom;
            justify-content: flex-start !important;
            align-items: center;
            height: 60vh;
            width: 100%;

            .card {
              width: 45%;
              margin-right: 0;
              margin-left: 0;
            }
          }
        }

        .section-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          &.text {
            width: 50%;
            text-align: left;
            align-self: flex-start;

            .title-wrapper {
              h1,
              .title-section {
                color: $light-grey;
                font-size: 60px;
                text-transform: uppercase;
                font-size: $fonts-bold;
                font-weight: 900;
                margin-bottom: 0;
                margin-top: 10px;
                margin-left: 0;
              }

              .flex {
                display: flex;
                align-items: center;

                h2 {
                  @include mediumHeading;
                  color: $main-orange;
                  margin-top: 10px;
                  margin-bottom: 15px;
                  padding-left: 10px;
                }

                .line {
                  width: 7px;
                  height: 30px;
                  background-color: $main-orange;
                  border-radius: 5px;
                }
              }
            }

            .text-wrapper {
              @include regularHeading;
              margin-bottom: 60px;
            }
          }

          &.card {
            text-align: left;
            width: 45%;
            @include sectionBase;
            z-index: 10;
            opacity: 1;
            margin-top: 50px;

            &.start {
              align-self: flex-start;
            }
            &.end {
              align-self: flex-end;
            }
            &.margin {
              margin-bottom: 30px;
            }

            h2 {
              @include regularLargeHeading;
              margin-bottom: 0;
              text-transform: uppercase;
            }

            p {
              color: $black-text;
            }

            .icon {
              width: 100px;
              height: auto;
            }

            .link {
              display: flex;
              align-items: center;
              cursor: pointer;
              // @include smallHeading;
              @include tableText;
              color: $main-orange;
            }
          }
        }
      }
    }
  }

  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    // display: flex;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: "";
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir="rtl"] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  .slick-list h3 {
    background: red;
    width: 200px;
  }
  .slick-track div:nth-child(1) {
    margin-right: 10px;
  }
  .slick-arrow {
    position: absolute;
    bottom: -40px;
    left: 95%;
    right: 0;
    width: 34px;
    height: 34px;
    padding: 0;
    text-indent: -9999px;
    background-repeat: no-repeat;
    background: $main-orange;
    border-radius: 50%;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
    }
    &.slick-prev {
      transform: translateX(-50%) translateX(-30px);
      z-index: 2;
      margin-top: 10px;
      &:after {
        // transform: translateX(-50%) translateX(-2px) translateY(-50%);
        border: solid white;
        border-radius: 2px;
        border-width: 0 3px 3px 0;
        padding: 5px;
        transform: translateX(-63%) translateX(30%) translateY(-45%) rotate(135deg);
        // -webkit-transform: rotate(135deg);
        // border-width: 9px 12px 9px 0;
        // border-color: transparent #fff transparent transparent;
      }
    }
    &.slick-next {
      transform: translateX(-50%) translateX(30px);
      z-index: 1;
      margin-top: 10px;
      &:after {
        // transform: translateX(-50%) translateX(2px) translateY(-50%);
        // border-width: 9px 0 9px 12px;
        // border-color: transparent transparent transparent #fff;
        border: solid white;
        border-radius: 2px;
        border-width: 0 3px 3px 0;
        padding: 5px;
        transform: translateX(-50%) translateX(-15%) translateY(-45%) rotate(-45deg);
      }
    }
    &.slick-disabled {
      cursor: default;
      background: $main-orange;
      border-radius: 50%;
      opacity: 0.5;
    }
  }
  .slides .slide {
    display: inline-block;
    padding: 10px 20px;
  }
  .slides .slide img {
    width: 200px;
  }

  .hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 100px;
    height: 100px;
    overflow: hidden;
  }

  .ytp-chrome-top.ytp-show-cards-title {
    display: none !important;
  }
}

@media screen and (max-width: 1450px) {
  .screen-wrapper.about-screen {
    .screen-inner-wrapper {
      margin-top: 80px;

      .main-section {
        &.top .benefits-container > div {
          p {
            font-size: 14px;
          }
          span {
            font-size: 10px;
          }
        }
        &.bottom {
          .row {
            height: 83vh;

            &.products {
              height: 44vh;
              height: fit-content;
              background-size: 63%;

              .section-wrapper.end.margin {
                margin-bottom: 0;
              }
            }

            &.services {
              height: 44vh;

              .card {
                width: 45%;
                margin-right: 0;
                margin-left: 0;
              }
            }
          }

          .section-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            &.text {
              width: 50%;
              text-align: left;
              align-self: flex-start;

              .title-wrapper {
                h1,
                .title-section {
                  color: $light-grey;
                  font-size: 60px;
                  text-transform: uppercase;
                  font-size: $fonts-bold;
                  font-weight: 900;
                  margin-bottom: 0;
                  margin-top: 10px;
                  margin-left: 0;
                }

                .flex {
                  display: flex;
                  align-items: center;

                  h2 {
                    @include mediumHeading;
                    color: $main-orange;
                    margin-top: 10px;
                    margin-bottom: 15px;
                    padding-left: 10px;
                  }

                  .line {
                    width: 7px;
                    height: 30px;
                    background-color: $main-orange;
                    border-radius: 5px;
                  }
                }
              }

              .text-wrapper {
                @include regularHeading;
                margin-bottom: 60px;
              }
            }

            &.card {
              text-align: left;
              width: 45%;
              @include sectionBase;
              z-index: 10;
              opacity: 1;
              margin-top: 50px;

              &.start {
                align-self: flex-start;
              }
              &.end {
                align-self: flex-end;
              }
              &.margin {
                margin-bottom: 10px;
              }

              h2 {
                @include regularLargeHeading;
                margin-bottom: 0;
                text-transform: uppercase;
              }

              p {
                color: $black-text;
              }

              .icon {
                width: 100px;
                height: auto;
              }

              .link {
                display: flex;
                align-items: center;
                cursor: pointer;
                // @include smallHeading;
                @include tableText;
                color: $main-orange;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .screen-wrapper.about-screen {
    .screen-inner-wrapper {
      margin-top: 80px;

      .main-section {
        &.top .benefits-container > div {
          p {
            font-size: 12px;
          }
          span {
            font-size: 8px;
          }
        }
        &.activities {
          .cards-wrapper {
            .section-wrapper.card {
              p {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

//tablet version 1024x1366
@media screen and (max-width: 1100px) and (min-height: 1200px) {
  .screen-wrapper.about-screen {
    .screen-inner-wrapper {
      .main-section {
        &.bottom {
          .row.products {
            .section-wrapper.card {
              margin-bottom: -30px;
            }
          }
          .row.services {
            height: 24vh;

            .section-wrapper.card {
              margin-bottom: -42px;
            }
          }
        }
      }
    }
  }
}

//tablet version 1024x768
@media screen and (min-width: 1000px) and (min-height: 768px) and (max-height: 1199px) {
  .screen-wrapper.about-screen {
    .screen-inner-wrapper {
      .main-section {
        &.bottom {
          .row.products {
            .section-wrapper.card {
              margin-bottom: -30px;
            }
          }
          .row.services {
            height: 42vh;

            .section-wrapper.card {
              margin-bottom: -42px;
            }
          }
        }
      }
    }
  }
}

//zoom - small desktop size
@media screen and (max-width: 1100px) and (max-width: 1199px) and (min-height: 500px) and (max-height: 700px) {
  .screen-wrapper.about-screen {
    .screen-inner-wrapper {
      .main-section {
        &.bottom {
          .row.products {
            .section-wrapper.card {
              margin-bottom: -30px;
            }
          }
          .row.services {
            height: 62vh;

            .section-wrapper.card {
              margin-bottom: -42px;
            }
          }
        }
      }
    }
  }
}

//1350
@media screen and (max-width: 1000px) {
  .screen-wrapper.about-screen {
    .screen-inner-wrapper {
      margin-top: 0;

      .main-section {
        &.top .benefits-container {
          position: relative;
          transform: none;
          // flex-direction: column;
          flex-wrap: wrap;
          margin: 30px 0;
          gap: 30px;
          width: 100%;
          > div {
            width: 45%;
            height: auto;
            .icon-container {
              width: 20vw;
              height: 20vw;
            }
            .text-container {
              padding-top: 20px;
              p {
                font-size: 16px;
              }
              span {
                font-size: 14px;
              }
            }
          }
        }
        &.top {
          padding: 0 20px;
          margin: 0;
          margin-top: 10px;
          box-shadow: none;
          flex-direction: column;

          .section-wrapper {
            &.left {
              width: 100%;

              .title-wrapper {
                h1,
                .title-section {
                  font-size: 26px;
                  font-family: $fonts-bold;
                }

                .flex {
                  h2 {
                    font-size: 16px;
                    font-family: $fonts-regular;
                  }
                }
              }
            }

            &.right {
              width: 100%;

              .text-wrapper {
                h1,
                .text-title {
                  font-size: 51px;
                }

                p {
                  font-size: 13px;
                }
              }

              .graphic-wrapper {
                // height: ;

                img {
                  width: 100%;
                }
              }
            }
          }
        }

        &.activities {
          padding: 20px 20px;
          background-size: 270vh;

          .title-wrapper {
            padding: 0;

            h1,
            .title-section {
              font-size: 26px;
              font-family: $fonts-bold;
            }

            .flex {
              h2 {
                font-size: 16px;
                font-family: $fonts-regular;
              }
            }
          }

          .cards-wrapper {
            flex-direction: column;
            padding: 0;
            width: 100%;

            .section-wrapper.card {
              padding: 20px 25px;
              height: unset;
              width: 100%;
              align-items: flex-start;
              text-align: left;

              h2 {
                font-size: 18px;
                font-family: $fonts-bold;
              }

              p {
                font-size: 16px;
                font-family: $fonts-regular;
              }

              .icon {
                width: 54px;
                height: 54px;
              }
            }
          }
          .benefits-container p {
            flex-basis: 100%;
          }
          .team-wrapper {
            margin-bottom: 10px;
            margin-top: 20px;
            margin-left: 0;
            margin-right: 0;

            .title-wrapper {
              padding: 0;

              h1,
              .title-section {
                font-size: 26px;
                font-family: $fonts-bold;
              }

              .flex {
                h2 {
                  font-size: 16px;
                  font-family: $fonts-regular;
                }
              }
            }

            .section-wrapper.video-card {
              .card-text-wrapper {
                gap: 5px;

                h2 {
                  font-size: 18px;
                }

                p {
                  font-size: 16px;
                  font-family: $fonts-regular;
                }
              }
            }
          }
        }

        &.brands {
          padding: 0 20px;
          margin: 0;

          .title-wrapper {
            h1,
            .title-section {
              font-size: 26px;
              font-family: $fonts-bold;
            }

            .flex {
              h2 {
                font-size: 16px;
                font-family: $fonts-bold;
              }
            }
          }

          .cards-wrapper {
            flex-direction: column;
            gap: 15px;

            &.desktop {
              display: none;
            }

            &.mobile {
              display: flex;
            }

            .card-wrapper {
              flex-basis: 0;
              width: 100%;

              &:hover {
                border: none;
                transition: 0.5s;

                img {
                  width: 100%;
                }
              }
            }
          }
        }

        &.bottom {
          margin-top: 40px;
          padding: 0 20px;

          .row {
            &.products,
            &.services {
              background-position: top;
              background-size: 100%;
              align-items: flex-end;
              position: relative;
              height: 400px;
              border-radius: 10px;

              .section-wrapper.card {
                margin: 0;
                width: 100%;

                background-color: none;
                background-color: rgba(255, 255, 255, 0.8);
                height: unset;
                min-height: fit-content;
                width: 100%;
                // margin-bottom: 33px;

                h2 {
                  font-size: 18px;
                  font-family: $fonts-medium;
                }

                p {
                  font-size: 16px;
                  margin-top: 10px;
                }

                .link {
                  span {
                    font-size: 14px;
                  }

                  img {
                    margin-top: 5px;
                    width: 45px;
                    height: 45px;
                  }
                }

                .icon {
                  width: 35px;
                  height: 35px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 2800px) {
  .screen-wrapper.about-screen {
    .screen-inner-wrapper {
      .main-section {
        &.activities {
          .team-wrapper {
            // width: 100%;

            .cards-wrapper {
              width: 100%;
            }
          }
        }

        &.bottom {
          .row {
            &.products,
            &.services {
              height: 750px;

              // .section-wrapper.card {
              //   padding: 80px 30px;
              //   margin-bottom: 75px;
              // }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 2200px) {
  .screen-wrapper.about-screen {
    .screen-inner-wrapper {
      .main-section {
        &.activities {
          .team-wrapper {
            // width: 100%;

            .cards-wrapper {
              width: 100%;
            }
          }
        }
        &.brands {
          margin-top: 30px;
        }

        &.bottom {
          .row {
            &.products,
            &.services {
              height: 660px;

              .section-wrapper.card {
                padding: 80px 30px;
                margin-bottom: 75px;
              }
            }
          }
        }
      }
    }
  }
}
