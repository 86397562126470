@import "../../../styles/vars.scss";

.screen-wrapper.offer {
  .screen-inner-wrapper {
    .form-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 2vw;
      position: relative;
      gap: 20px;

      .section-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // justify-content: center;
        @include sectionBase;
        padding: 15px 25px;
        width: 100%;

        .section-title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: nowrap;
          margin-bottom: 0;
          width: 100%;

          img {
            min-height: 100%;
            margin-right: 10px;
            height: 40px;
            cursor: pointer;
          }

          h2 {
            @include mediumHeading;
            text-transform: uppercase;
            font-size: 20px;
            margin-top: 0;
            margin-bottom: 0;
          }

          p {
            margin: 0;
            font-size: 14px;
          }

          .flex {
            display: flex;
            align-items: center;

            &.start {
              justify-content: flex-start;
              text-align: left;
            }
            &.col {
              flex-direction: column;
              align-items: flex-start;
            }
          }
        }

        .section-content {
          display: flex;
          justify-content: space-between;
          margin-top: 25px;
          gap: 15px;
          align-items: stretch;
          width: 100%;

          &.start {
            justify-content: flex-start;
            width: 100%;

            .card {
              width: 250px;
            }
          }
          &.no-margin {
            margin-top: 0;
          }

          .card {
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: space-between;
            @include sectionBase;
            z-index: 10;
            opacity: 1;
            margin: 0;
            padding: 10px 15px;
            transition: 1s;
            cursor: pointer;
            flex: 1;
            transition: 0.5s;

            &.start {
              align-self: flex-start;
            }
            &.end {
              align-self: flex-end;
            }
            &.margin {
              margin-bottom: 90px;
            }
            &.selected {
              background-color: $main-orange;
              transition: 0.5s;

              h2 {
                color: $white;
                font-family: $fonts-bold;
              }

              .icon {
                fill: $white;
                filter: brightness(0) invert(1);
              }
            }

            h2 {
              @include regularLargeHeading;
              font-family: $fonts-bold;
              text-transform: none;
              font-size: 16px;
              margin: 0;
            }

            .icon {
              width: auto;
              height: 27px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

//zoom - small desktop size
@media screen and (max-width: 1100px) and (min-height: 500px) and (max-height: 700px) {
  
}

//1350
@media screen and (max-width: 1000px) {
  .screen-wrapper.offer {
    height: fit-content;
    .screen-inner-wrapper {
      height: fit-content;
      padding: 0 20px;

      .form-wrapper {
        padding: 0;

        .section-wrapper {
          padding: 20px 15px;

          .section-title {
            h2 {
              font-size: 20px;
              margin-top: 0;
              margin-bottom: 10px;
            }

            p {
              font-size: 16px;
            }
          }

          .section-content {
            flex-direction: column;

            &.start {
              width: 100%;
              .card {
                width: 100%;
              }
            }

            .card {
              width: 100%;
              display: flex;
              flex-wrap: nowrap;

              h2 {
                font-size: 16px;
                order: 2;
              }

              .icon {
                order: 1;
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .screen-wrapper.offer {
    .screen-inner-wrapper {
      .form-wrapper {
        .offer-inputs-wrapper {
          &.manufacturers-energy {
            .section-wrapper {
              .section-content {
                .flex {
                  display: block;
                  &.start {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    label + label{
                      margin-top:10px;
                    }
                  }
                  .primary-button {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 2200px) {
  .screen-wrapper.offer {
    .screen-inner-wrapper {
      min-height: calc(100vh - 316px);
    }
  }
}
