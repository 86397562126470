@import '../../styles/vars.scss';

.screen-wrapper.partners {
    .screen-inner-wrapper {

        .main-section {

            // &.top {
            //     // min-height: 85vh; 
            // }

            &.activities {
                background-image: url('../../assets/images/about-screen-blue-background.webp');
                background-size: 100%;
                background-repeat: no-repeat;
                margin-top: 50px;
                padding: 50px 0;
                min-height: 80vh;

                .title-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0 2vw;
                    margin-bottom: 30px;
            
                    h1,
                    .title-section {
                        color: $white;
                        font-size: 60px;
                        text-transform: uppercase;
                        font-family: $fonts-bold;
                        font-weight: 900;
                        margin-bottom: 0;
                        margin-top: 10px;
                        margin-left: 0;
                    }
    
                    .flex {
                        display: flex;
                        align-items: center;
    
                        h2 {
                            @include mediumHeading;
                            color: $white;
                            margin-top: 10px;
                            margin-bottom: 15px;
                            padding-left: 10px;
                        }
    
                        .line {
                            width: 7px;
                            height: 30px;
                            background-color: $white;
                            border-radius: 5px;
                        }
                    }
                }

                .text-wrapper {
                    @include regularHeading;
                    color: $white;
                    margin: 5px 0;
                    text-align: left;
                }

                .cards-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 15px;
                    padding: 0 2vw;
                    margin-bottom: 20px;

                    .card-wrapper {
                      display: flex !important;
                      justify-content: center;
                      align-items: center;
                      width: 100%;
                      height: 100%;
                      flex-basis: 350px;
                      transition: 0.5s;
                      cursor: default;

                      &:hover {
                          border: 4px solid $main-orange;
                          border-radius: 10px;
                          transition: 0.5s;

                          img {
                              width: 95%;
                          }
                      }

                      img {
                          width: 100%;
                          object-fit: cover;
                          cursor: pointer;
                      }
                  }

                    &.slider {
                        display: inline-block;
                        // display: flex;
                        // align-items: center;
                        // justify-content: center;
                        // gap: 15px;
                        padding: 0 2vw;
                        margin-bottom: 20px;
        
                        img {
                            justify-content: flex-start;
                        }
    
                        .section-wrapper {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: 100%;            
                        }
                    }
                    &.mobile {
                      display: none;
                    }
                    
    
                    img {
                        justify-content: flex-start;
                    }

                    .section-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 100%;            

                        &.card {
                            text-align: center;
                            align-items: center;
                            justify-content: flex-start;
                            @include sectionBase;
                            background-color: $medium-blue;
                            box-shadow: none;
                            border: 1px solid rgba(195, 191, 191, 0.562);
                            z-index: 10;
                            opacity: 1;
                            margin: 0;
                            padding: 45px 55px;
                            height: 350px;
                            transition: 1s; 
                
                            &.start {
                                align-self: flex-start;
                            }
                            &.end {
                                align-self: flex-end;
                            }
                            &.margin {
                                margin-bottom: 90px;
                            }

                            p {
                                color: $white;
                                font-size: 20px;
                                flex: 0.5;
                                align-self: center;
                            }
                
                            .icon {
                                width: auto;
                                height: 40px;
                                margin-top: 35px;
                            }
                
                            .link {
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                // @include smallHeading;
                                @include tableText;
                                color: $white;
                            }
                        }
                    }
                }

                .brands-wrapper {
                    @include sectionBase;
                    display: flex;
                    // align-items: center;
                    margin-bottom: 40px;
                    margin: 0 2vw;
                    display: flex;
                    flex-direction: column;

                    .title-wrapper {
                        align-self: flex-start;
            
                        h1,
                        .title-section {
                            color: $light-grey;
                            font-size: 60px;
                            text-transform: uppercase;
                            font-size: $fonts-bold;
                            font-weight: 900;
                            margin-bottom: 0;
                            margin-top: 10px;
                            margin-left: 0;
                        }
        
                        .flex {
                            display: flex;
                            align-items: center;
        
                            h2 {
                                @include mediumHeading;
                                color: $main-orange;
                                font-size: 24px;
                                margin-top: 10px;
                                margin-bottom: 15px;
                                padding-left: 10px;
                            }
        
                            .line {
                                width: 7px;
                                height: 30px;
                                background-color: $main-orange;
                                border-radius: 5px;
                            }
                        }
                    }

                    .cards-wrapper.videos {
                      height: 50vh;
                      height: 500px;
                      width: 100%;
                      padding: 0 40px;

                      .slick-slider.slick-initialized {
                        width: 100%;
                      }
                    }

                    .cards-wrapper.slider {
                      padding: 0 40px;
                      // height: 220px;
          

                      .slick-slider.slick-initialized {
                        width: 100%;
                      }
                    }

                    .section-wrapper {

                        &.video-card {
                            box-shadow: none;
                            z-index: 10;
                            opacity: 1;
                            margin: 0;
                            width: 100%;
                            min-height: 100%;
                            align-items: flex-start;
                            padding: 50px 30px;
                            border: 1px solid rgba(195, 191, 191, 0.562);
                            padding: 15px 25px;
                            align-items: center;
                            border-radius: 10px;
                            align-self: stretch;

                            .card-image {
                                width: 100%;
                                border-radius: 10px;
                            }
    
                            .card-text-wrapper {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                                gap: 15px;
                                width: 100%;
                                flex: 1;
    
                                h2 {
                                    @include boldHeading;
                                    font-size: 20px;
                                    text-transform: uppercase;
                                    text-align: center;
                                    width: 100%;
                                    margin-bottom: 0;
                                    text-transform: uppercase;
                                }
    
                                p {
                                    margin: 0;
                                    font-size: 18px;
                                    font-family: $fonts-light;
                                    text-align: center;
                                }

                                &.center {

                                  p {
                                    width: 100%;
                                    text-align: center;
                                  }
                                }
                            }
                
                        }
                    }
                }
            }

            &.services {
                @include sectionBase;
                display: flex;
                align-items: center;
                margin: 0 2vw;
                margin-bottom: 60px;

                .section-wrapper {

                    &.left {
                        text-align: left;
                        width: 45%;

                        .flex {
                            display: flex;
                            align-items: center;

                            &.small {
                                p {
                                    padding-left: 10px;
                                }
                            }
        
                            h2 {
                                @include mediumHeading;
                                color: $main-orange;
                                margin-top: 10px;
                                margin-bottom: 15px;
                                padding-left: 10px;
                            }

                            p {
                              font-size: 18px;
                            }
        
                            .line {
                                width: 7px;
                                height: 30px;
                                background-color: $main-orange;
                                border-radius: 5px;
                            }
                        }
            
                        .title-wrapper {
                            h1,
                            .title-section {
                                color: $light-grey;
                                font-size: 60px;
                                text-transform: uppercase;
                                font-size: $fonts-bold;
                                font-weight: 900;
                                margin-bottom: 0;
                                margin-top: 10px;
                                margin-left: 0;
                            }
                        }
            
                        .text-wrapper {
                            @include regularHeading;
                            margin-bottom: 60px;
                        }
                    }

                    &.right {
                        width: 55%;

                        .text-wrapper {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            h1,
                            .title-section {
                                margin: 0;
                                font-size: 100px;
                                font-family: $fonts-bold;
                                color: $main-orange;
                            }

                            p {
                                margin: 0;
                                font-size: 30px;
                                font-family: $fonts-medium;
                            }
                        }

                        .graphic-wrapper {
                            margin-top: 20px;
                            img {
                                width: 90%;
                            }
                        }
                    }
                }
            }
        }
    }

    .slider-all {
      // background-color: purple;
      // overflow: hidden;
      height: 110px;

      .slick-slider {
        width: 100%;
        height: 100%;

        // background-color: red;
      }
      .slick-list {
        // background-color: teal;
        height: 100%;
      }
      .slick-track {
        height: 100%;

      }
      .slick-prev {
        left: 0;
        transform: translate(-30px , -70%)!important;
      }
      .slick-next {
        left: 100%;
        transform: translate(5px , -70%)!important;
      }
      .slick-slide {
        height: 100%;

        div {
          height: 100%;

        }
      }
    }
    .slick-slider {
        position: relative;
      
        display: block;
        box-sizing: border-box;
      
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
      }
      .slick-initialized {
        width: 95vw;
      }
      .slick-list {
        position: relative;
      
        display: block;
        overflow: hidden;
      
        margin: 0;
        padding: 0;
      }
      .slick-list:focus {
        outline: none;
      }
      .slick-list.dragging {
        cursor: pointer;
        cursor: hand;
      }
      
      .slick-slider .slick-track,
      .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
      
      .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        // display: flex;
        margin-left: auto;
        margin-right: auto;
      }
      .slick-track:before,
      .slick-track:after {
        display: table;
      
        content: "";
      }
      .slick-track:after {
        clear: both;
      }
      .slick-loading .slick-track {
        visibility: hidden;
      }
      
      .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;

        // &.slick-cloned,
        // .slick-current {
        //   width: 0px;

        // }
      }
      [dir="rtl"] .slick-slide {
        float: right;
      }
      .slick-slide img {
        display: block;
      }
      .slick-slide.slick-loading img {
        display: none;
      }
      .slick-slide.dragging img {
        pointer-events: none;
      }
      .slick-initialized .slick-slide {
        display: block;
      }
      .slick-loading .slick-slide {
        visibility: hidden;
      }
      .slick-vertical .slick-slide {
        display: block;
      
        height: auto;
      
        border: 1px solid transparent;
      }
      .slick-arrow.slick-hidden {
        display: none;
      }
      .slick-list h3 {
        width: 200px;
      }
      .slick-track div:nth-child(1) {
        margin-right: 10px;
      }
      .slick-arrow {
        position: absolute;
        bottom: -40px;
        left: 95%;
        right: 0;
        width: 34px;
        height: 34px;
        padding: 0;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background: $main-orange;
        border-radius: 50%;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
        }
        &.slick-prev {
          transform: translateX(-50%) translateX(-30px);
          z-index: 2;
          margin-top: 10px;
          &:after {
            // transform: translateX(-50%) translateX(-2px) translateY(-50%);
            border: solid white;
            border-radius: 2px;
            border-width: 0 3px 3px 0;
            padding: 5px;
            transform: translateX(-63%) translateX(30%) translateY(-45%) rotate(135deg);
            // -webkit-transform: rotate(135deg);
            // border-width: 9px 12px 9px 0;
            // border-color: transparent #fff transparent transparent;
          }
        }
        &.slick-next {
          transform: translateX(-50%) translateX(30px);
          z-index: 1;
          margin-top: 10px;
          &:after {
            // transform: translateX(-50%) translateX(2px) translateY(-50%);
            // border-width: 9px 0 9px 12px;
            // border-color: transparent transparent transparent #fff;
            border: solid white;
            border-radius: 2px;
            border-width: 0 3px 3px 0;
            padding: 5px;
            transform: translateX(-50%) translateX(-15%) translateY(-45%) rotate(-45deg);
          }
        }
        &.slick-disabled {
          cursor: default;
          background: $main-orange;
          border-radius: 50%;
          opacity: 0.5;
        }
      }
      .slides .slide {
        display: inline-block;
        padding: 10px 20px;
      }
      .slides .slide img {
        width: 200px;
      }
      
      .hidden {
        position: absolute;
        left: -10000px;
        top: auto;
        width: 100px;
        height: 100px;
        overflow: hidden;
      }
}

@media screen and (max-width: 1350px) {
  .screen-wrapper.partners {

    .screen-inner-wrapper {

      .main-section {

        &.activities {

          .cards-wrapper {
            align-items: stretch;

            .section-wrapper.card {
              padding: 20px 25px;
              height: unset;

              p {
                font-size: 18px;
              }

              .icon {
                margin: 10px;
              }
            }
          }

          .brands-wrapper {

            .cards-wrapper {

              .section-wrapper.video-card {

                .card-text-wrapper {

                  p {
                    font-size: 18px;
                    font-family: $fonts-regular;
                  }
                }
              }

            }
          }
        }

        &.services {
          justify-content: space-between;
          gap: 20px;

          .section-wrapper.left {
            p {
              font-size: 18px;
            }
          }

          .section-wrapper.right {

            .graphic-wrapper {
              // width: 90%;

              img {
                  width: 100%;
              }
          }
          }
        }
      }
    }
  }
}

//1350
@media screen and (max-width: 1000px) {
  .screen-wrapper.partners {

    .screen-inner-wrapper {

      .main-section {

        &.activities {
          padding: 20px 20px;
          background-size: 340vh;

          .title-wrapper {

            h1 {
              font-size: 26px;
              font-family: $fonts-bold;
            }

            .flex {

              h2 {
                font-size: 16px;
                font-family: $fonts-regular;
              }
            }
          }

          .text-wrapper {
            font-size: 16px;
          }

          .cards-wrapper {
            flex-direction: column;

            .section-wrapper.card {
              padding: 20px 25px;
              height: unset;

              p {
                font-size: 16px;
              }

              .icon {
                margin: 10px;
              }
            }
          }

          .brands-wrapper {

            .title-wrapper {

              &.slider {
                margin-bottom: 10px;
                margin-top: 20px;
                padding: 0;
              }

              h1,
              .title-section {
                font-size: 26px;
                font-family: $fonts-bold;
              }
  
              .flex {
  
                h2 {
                  font-size: 16px;
                  font-family: $fonts-regular;
                }
              }
            }

            .cards-wrapper {

              &.desktop {
                display: none;
              }
              &.mobile {
                display: flex;
              }
              &.slider {
                padding: 0;

                .card-wrapper {
                  flex-basis: unset;
                  margin-left: 0;

                  &:hover {
                    border: none;

                    img {
                        width: 100%;
                    }
                }
                }
              }

              .section-wrapper.video-card {

                .card-text-wrapper {
                  gap: 5px;

                  h2 {
                    font-size: 18px;
                  }

                  p {
                    font-size: 16px;
                    font-family: $fonts-regular;
                  }
                }
              }

            }
          }
        }

        &.services {
          flex-direction: column;
          box-shadow: none;
          gap: 0;
          justify-content: flex-start;

          .section-wrapper.left {
            width: 100%;
            
            .title-wrapper {

              h1,
              .title-section {
                font-size: 26px;
                font-family: $fonts-bold;
              }
  
              .flex {
  
                h2 {
                  font-size: 16px;
                  font-family: $fonts-regular;
                }
              }
            }
  
            .text-wrapper {
              font-size: 16px;
            }
          }

          .section-wrapper.right {
            width: 100%;

            .graphic-wrapper {
              width: 100%;

              img {
                  width: 100%;
              }
          }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 2200px) {
  .screen-wrapper.partners {

    // .slick-slider {
    //   .svlick-list {
    //     height: 120%;
    //   }
    // }

    // .slick-slider.slick-initialized {
    //   height: 120%;
    // }

    .screen-inner-wrapper {

      .main-section {

        &.activities {

          .brands-wrapper {

            .cards-wrapper {
              &.slider {
                height: 290px;
              }
              &.videos {
                height: 500px;
              }
            }
          }
        }

      }
    }
  }
}

@media screen and (min-width: 3000px) {
  .screen-wrapper.partners {

    .screen-inner-wrapper {

      .main-section {

        &.activities {

          .brands-wrapper {

            .cards-wrapper {
              &.slider {
                height: 300px;
              }
              &.videos {
                height: 520px;
              }
            }
          }
        }

      }
    }
  }
}