@import "./fonts";

$fonts-thin: "thin";
$fonts-light: "light";
$fonts-regular: "regular";
$fonts-medium: "medium";
$fonts-bold: "bold";

$white: #ffffff;
$white-blue: #F5F7FA;
$heading-blue: #F7F8FF;
$light-blue: #E6EDFF;
$light-orange: #FDF9F5;
$main-orange: #FE6831;
$blue-grey: #4D5D6E;
$grey: #7E7E7E;
$medium-blue: #032767;
$blue: #060095;
$dark-blue: #002157;
$dark: #61616C;
$black-text: #313131;
$red: #FA4444;
$light-grey: #D3D3D3;
$text-grey: #8E8E8E;
$border: #909090;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin lightLabel {
  font-size: 12px;
  font-family: $fonts-light;
  color: $light-grey;
}

@mixin smallestHeading {
  font-size: 14px;
  font-family: $fonts-regular;
  color: $black-text;
}

@mixin smallestHeadingMedium {
  font-size: 14px;
  font-family: $fonts-medium;
  color: $black-text;
}
@mixin smallestHeadingBold {
  font-size: 14px;
  font-family: $fonts-bold;
  color: $black-text;
}

@mixin inputLabel {
  font-size: 16px;
  font-family: $fonts-light;
  color: $light-grey;
}

@mixin standardText {
  font-size: 16px;
  font-family: $fonts-regular;
  color: $black-text;
}

@mixin standardMediumText {
  font-size: 16px;
  font-family: $fonts-medium;
  color: $black-text;
}

@mixin standardBoldText {
  font-size: 16px;
  font-family: $fonts-bold;
  color: $black-text;
}

@mixin smallHeading {
  font-size: 18px;
  font-family: $fonts-medium;
  color: $black-text;
}

@mixin regularHeading {
  font-size: 18px;
  font-family: $fonts-regular;
  color: $black-text;
}

@mixin boldHeading {
  font-size: 18px;
  font-family: $fonts-bold;
  color: $black-text;
}

@mixin smallHeadingMobile {
  font-size: 16px;
  font-family: $fonts-bold;
  color: $black-text;
}

@mixin tableText {
  font-size: 16px;
  font-family: $fonts-medium;
  color: $black-text;
}

@mixin mediumHeadingMobile {
  font-size: 18px;
  font-family: $fonts-bold;
  color: $black-text;
}

@mixin regularLargeHeading {
  font-size: 24px;
  font-family: $fonts-regular;
  color: $grey;
}

@mixin mediumHeading {
  font-size: 26px;
  font-family: $fonts-bold;
  color: $black-text;
}

@mixin primaryBttn {
  padding: 0.8em 3em;
  padding-bottom: 0.9em;
  background-color: $main-orange;
  border-radius: 6px;
  border-style: none;
  color: white;
  cursor: pointer;
  transition: 0.5s;
  // margin: 0.5em;
  font-size: 0.8rem;
  text-transform: uppercase;
  transition: 0.5s;
  font-family: $fonts-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.25rem; 

  &:hover {
    opacity: 0.8;
    transition: 0.5s;
  }

  &:disabled {
    opacity: 0.5;
    transition: 0.5s;
    cursor: default;
  }
}

@mixin secondaryBttn {
  @include primaryBttn();
  // padding: 0.9em 2.6em;
  color: $black-text;
  background-color: $white;
  font-size: 0.8rem;
  @include basicShadow;
}

@mixin optionButton {
  @include secondaryBttn();
  @include sectionShadow();
  border: none;

  &.selected {
    color: $white;
    background-color: $main-orange;
  }
}

@mixin uploadBttn {
  @include secondaryBttn;
  width: 236px;
  border: 1px dashed $grey;
  display: flex;
  align-items: center;
  padding: 2px 10px;
  font-size: 14px;
  font-family: $fonts-medium;

  &:invalid {
    opacity: 0.5;
    border: 1px dashed $red;
  }

  img {
    width: 19px;
    height: 19px;
    margin-left: 10px;
  }
}

@mixin flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin sectionHeader {
  @include section;
  padding: 10px 15px;
  margin-bottom: 20px;
}

@mixin input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  outline-style: none;
  border: 1px solid $grey;
  margin-top: 5px;
}

@mixin basicShadow {
  // box-shadow: 0px 0px 16px -10px $shadow-color;
  box-shadow: 0px 0px 10px -4.5px rgba(0, 0, 0, 0.3); //equal on 4 sides
}

@mixin bottomShadowLight {
  box-shadow: 0px 3px 6px #00000017;
}

@mixin sectionShadow {
  box-shadow: 0px 5px 15px 0px rgba(0, 52, 112, 0.15);
}

@mixin bottomShadow {
  box-shadow: 0 5px 5px rgba(0, 52, 112, 0.15);
}

@mixin scrollbar() {
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 0;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}

@mixin scrollbarHorizontal() {
  scrollbar-color: #999;
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 4px;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}

@mixin hiddenScrollbar() {
  scrollbar-color: transparent;
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}

@mixin sectionBase {
  margin: 0 auto;
  @include sectionShadow;
  background-color: white;
  border-radius: 10px;
  padding: 15px 20px;
}

@mixin section {
  width: 94%;
  color: $white;
  @include sectionShadow;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  h3 {
    @include boldHeading;
    text-transform: uppercase;
  }
}

@mixin input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  outline-style: none;
  border: 1px solid $grey;
  margin-top: 5px;
}

