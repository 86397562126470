@import "../../../styles/vars.scss";

.offer-inputs-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 20px;

    .flex {
        display: flex;
        align-items: center;
        width: 100%;

        &.start {
          justify-content: flex-start;
          text-align: left;
        }
        &.col {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .primary-button {
        @include primaryBttn;
        @include sectionShadow;
    }

    .section-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        width: 100%;
        margin-bottom: 0;

        img {
          min-height: 100%;
          margin-right: 10px;
          height: 40px;
          cursor: pointer;
        }

        h2 {
          @include mediumHeading;
          text-transform: uppercase;
          font-size: 20px;
          margin-top: 0;
          margin-bottom: 10px;
        }

        p {
          margin: 0;
          font-size: 14px;
        }
    }

    .section-content {
        display: flex;
        justify-content: flex-start;
        gap: 15px;
        // align-items: stretch;
        width: 100%;
        flex-wrap: wrap;

        &.start {
            justify-content: flex-start;
            width: 100%;

            .card {
                width: 250px;
            }
        }
        &.col {
            flex-direction: column;
            align-items: flex-start;
        }
        &.no-margin {
            margin-top: 0;
        }

        .input-wrapper {
            margin: 0 32px 15px 0;
            width: 100%;
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        
            &.small {
              flex: 1;
              max-width: 200px;
            }
            &.medium {
              flex: 1.2;
              max-width: 250px;
            }
            &.large {
              flex: 2;
              max-width: 450px;
            }
            &.last {
              margin-right: 0;
            }
            &.radio {
              height: unset;
            }
            &.wide {
              min-width: 100%;
              flex: 1;
              align-self: stretch;
            }
            &.calendar {
                .calendar-icon {
                    position: absolute;
                    right: 8px;
                    top: 10px;
                  }
              
                  .calendar-wrapper {
                    position: relative;
                    // width: 250px;
                    width: 100%;

                    .react-datepicker {
                      border: none;
                      @include sectionShadow;

                      .react-datepicker__month-container,
                      .react-year-container {
                        font-family: $fonts-regular;
                        font-size: 14px;
                      }

                      .react-datepicker__triangle::before {
                        // border: none;
                        background-color: white;
                        border: 1px solid $white;
                        border-bottom-color: $white;
                      }
                      .react-datepicker__triangle::after {
                        // border: none;
                        // background-color: white;
                        border: 1px solid $white;
                        border-bottom-color: $white;
                      }
                      .react-datepicker__header.react-datepicker-year-header {
                        background-color: $white;
                        font-family: $fonts-bold;
                        border-bottom: none;

                        .react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous {
                          color: $main-orange;
                        }
                      }

                      .react-datepicker__month-text.react-datepicker__month-text--keyboard-selected {
                          background-color: $main-orange;
                          color: $white;
                          font-family: $fonts-bold;
                      }

                      .react-datepicker__month-container {
                        .react-datepicker__navigation.react-datepicker__navigation--previous {
                          width: 50%;
                        }
                        .react-datepicker__navigation.react-datepicker__navigation--next {
                          width: 50%;
                        }
                      }

                      .react-datepicker__year-wrapper {
                        display: flex;
                        justify-content: center;

                        .react-datepicker__year-text--selected {
                          background-color: $main-orange;
                        }

                        .react-datepicker__year-text.react-datepicker__year-text--keyboard-selected {
                          background-color: white;
                        }
                      }
                    }

                    // .react-datepicker__month.react-datepicker__monthPicker {
                    //     visibility: hidden;
                    // } 

                    // .react-datepicker-popper div {
                    //     // min-width: 100vw;
        
                    //     .react-datepicker {
        
                    //       .react-datepicker__month-container {
                    //         min-width: 100vw;
        
                    //         .react-datepicker__month {
                    //           min-width: 100vw;
                    //         }
                    //       }
                    //     }
                    //   }
              
                    .react-datepicker__year-dropdown {
                      height: 220px;
                      overflow-y: scroll;
                      @include scrollbar;
                    }
                  }
            }
            &.text-icon {
                .text-input-wrapper {
                    position: relative;
                    width: 100%;
                    // max-width: 245px;
              
                    .input-text {
                      position: absolute;
                      right: 8px;
                      top: -5px;
                      padding-left: 8px;
                      border-left: 1px solid $medium-blue;
                      font-size: 16px;
                      font-family: $fonts-medium;
                    }
                }
            }
            &.dropdown {
                display: flex;
                justify-content: flex-start;
                position: relative;
                // flex: 2;
                max-width: 450px;

                .arrow {
                    position: absolute;
                    right: 15px;
                    top: 16px;
                    cursor: pointer;
                    transition: 0.5s;
                    transform: rotate(180deg);
              
                    &.selected {
                      transform: rotate(0deg);
                      transition: 0.5s;
                    }
                }

                .dropdown-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    overflow-y: scroll;
                    max-height: 300px;
                    @include scrollbar;
                    position: absolute;
                    background-color: $white;
                    @include sectionShadow;
                    border-radius: 8px;
                    padding: 5px;
                    z-index: 100;
                    top: 50px;
                    width: 100%;
              
                    &.small {
                      max-height: 120px;
                    }
              
                    .dropdown-element {
                      padding: 10px 5px;
                      transition: 0.5s;
                      cursor: pointer;
                      font-size: 18px;
                      border-radius: 8px;
              
                      &:hover {
                        background-color: $light-blue;
                        transition: 0.5s;
                      }
                    }
                  }
            }
        
            p {
              @include smallestHeading;
              font-size: 14px;
              text-align: left;
        
              .orange {
                color: $main-orange;
              }
              .bold {
                font-family: $fonts-bold;
              }
            }

            input {
                margin: 0;
                height: 43px;
                width: 100%;
                border-radius: 8px;
                border-style: none;
                @include smallestHeadingMedium;
                font-size: 14px;
                padding: 0 10px;
                border: 1px solid #043c5e6a;
                outline: none;
              }
        
            .label-wrapper {
              display: flex;
              justify-content: flex-start;
            }
        
            .extra-info {
              position: absolute;
              margin-top: 8px;
              white-space: nowrap;
            }
          }
    }
}

@media screen and (max-width: 1700px) {
  .offer-inputs-wrapper {
    .section-content {
      .input-wrapper p {
        font-size: 14px;
      }

      .flex.start {
        .flex.half {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .offer-inputs-wrapper {
    .section-content {
      .input-wrapper p {
        font-size: 12px;
      }
    }
  }
}