@import "../../styles/vars.scss";

.screen-wrapper.contact {
  .screen-inner-wrapper {
    margin: 20px 0;
    display: flex;

    .mobile {
      display: none;
    }

    .main-section-wrapper {
      display: flex;
      // align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      margin: 0 2vw;

      &.map {
        text-align: center;
        width: 47%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -5%;

        p {
          font-size: 22px;
          font-family: $fonts-light;
        }

        .map {
          background-image: url("../../assets/images/bg.svg");
          background-size: contain;
          background-repeat: no-repeat;
          height: 50%;
          width: 100%;
        }
      }

      &.text {
        @include sectionBase;
        margin-right: 0;
        text-align: left;
        width: 47%;
        padding: 0 20px 20px 20px;
      }

      .title-wrapper {
        margin-bottom: 15px;
        h1 {
          color: $light-grey;
          font-size: 60px;
          text-transform: uppercase;
          font-family: $fonts-bold;
          font-weight: 900;
          margin-bottom: 0;
          margin-top: 10px;
          margin-left: 0;
        }

        .flex {
          display: flex;
          align-items: center;
          margin-left: 5.2px;

          h2 {
            @include mediumHeading;
            font-size: 26px;
            color: $main-orange;
            margin-top: 10px;
            margin-bottom: 15px;
            padding-left: 10px;
          }

          .line {
            width: 7px;
            height: 30px;
            background-color: $main-orange;
            border-radius: 5px;
          }
        }
      }

      .text-wrapper {
        @include regularHeading;
        margin-bottom: 30px;
        width: 78%;

        .flex {
          display: flex;
          align-items: flex-start;

          &.rows {
            flex-wrap: wrap;
            // gap: 15px;

            .row {
              @include regularHeading;
              color: $main-orange;
              font-family: $fonts-bold;
              margin: 0 0 10px 0;
              // padding-left: 10px;
              margin-right: 15px;
            }

            span {
              color: $black-text;
              font-family: $fonts-regular;
            }
          }

          .line {
            width: 7px;
            height: 30px;
            background-color: $main-orange;
            border-radius: 5px;
          }
        }
      }

      .scroll-wrapper {
        height: 450px;
        overflow-y: auto;
        @include scrollbar;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 15px;
        // width: 78%;
        margin-bottom: 10px;

        &.mobile {
          display: none;
        }

        &::-webkit-scrollbar {
          width: 2px;
        }

        .region-info-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;

          h2 {
            // all: initial;
            font-family: $fonts-bold;
            font-size: 20px;
          }

          .row-card-wrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: flex-start;
            border: 1px solid rgba(195, 191, 191, 0.562);
            padding: 20px 25px;
            border-radius: 10px;
            text-align: left;
            // width: 98%;
            position: relative;
            .person-image {
              height: calc(100% - 30px);
              width: auto;
              aspect-ratio: 1;
              border-radius: 10px;
              position: absolute;
              right: 25px;
              top: 50%;
              transform: translateY(-50%);
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
            h3 {
              @include regularHeading;
              margin: 0;
              margin-bottom: 10px;
              font-family: $fonts-bold;
              text-transform: uppercase;
              padding-right: 60px;
            }
            p {
              all: initial;
              @include regularHeading;
            }

            .row-icon-wrapper {
              display: flex;
              justify-content: flex-start;

              .icon {
                width: 22px;
                margin-right: 10px;
              }
            }
          }
        }
      }

      .card-non-selected {
        font-size: 18px;
        font-family: $fonts-light;
        text-align: center;
        color: $main-orange;

        &.margin {
          margin-top: 50px;
        }
      }
    }
  }
}

.svg-container {
  width: 100%;
  height: 60%;
}

//zoom - small desktop size; tablet version 1024x768 1024x1366
@media screen and (max-width: 1200px) {
  .screen-wrapper.contact {
    .screen-inner-wrapper {
      .main-section-wrapper {
        &.map {
          p {
            font-size: 18px;
            font-family: $fonts-light;
          }
        }

        &.text {
          padding: 5px 5px 15px 30px;
        }

        .scroll-wrapper {
          .region-info-wrapper {
            .row-card-wrapper {
              p {
                font-size: 16px;
              }
            }
          }
        }

        .card-non-selected {
          font-size: 16px;
        }
      }
    }
  }
}

//1350
@media screen and (max-width: 550px) {
  .screen-wrapper.contact
    .screen-inner-wrapper
    .main-section-wrapper
    .scroll-wrapper
    .region-info-wrapper
    .row-card-wrapper
    .person-image {
    height: calc(100% - 70px);
  }
}
@media screen and (max-width: 1000px) {
  .screen-wrapper.contact {
    .screen-inner-wrapper {
      flex-direction: column;
      margin-top: 5px;

      .main-section-wrapper {
        &.desktop {
          display: none;
        }
        &.text {
          width: 100%;
          box-shadow: none;
          padding: 0 20px;

          .section-wrapper {
            width: 100%;

            .title-wrapper {
              margin-bottom: 20px;

              h1 {
                font-size: 26px;
              }
              h2 {
                font-size: 16px;
              }
            }

            .text-wrapper {
              @include sectionBase;
              margin: 0 0px 20px 0px;
              width: 100%;

              p {
                font-size: 16px;
                margin-top: 5px;
              }

              .flex {
                flex-direction: column;

                .row {
                  padding-left: 0;
                }
              }
            }

            .scroll-wrapper {
              width: 100%;
              margin-top: 15px;
              overflow-y: visible;
              height: fit-content;

              &.mobile {
                display: flex;
              }
              &.desktop {
                display: none;
              }

              .region-info-wrapper {
                h2 {
                  font-size: 20px;
                }

                .row-card-wrapper {
                  padding: 15px;
                  .person-image {
                    right: 15px;
                  }
                }
              }
            }
          }
        }

        // .desktop {
        //   display: none;
        // }
        // .mobile {
        //   display: flex;
        // }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .screen-wrapper.contact {
    .screen-inner-wrapper {
      .main-section-wrapper {
        &.text {
          width: 100%;
          box-shadow: none;
          padding: 0 20px;

          .section-wrapper {
            .scroll-wrapper {
              height: unset;
              margin-top: 15px;

              &.mobile {
                display: flex;
              }
              &.desktop {
                display: none;
              }
            }

            .card-non-selected {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 2200px) {
  .screen-wrapper.contact {
    .screen-inner-wrapper {
      .main-section-wrapper {
        &.text {
          padding-top: 30px;

          .section-wrapper {
            width: 100%;

            .title-wrapper {
              h1 {
                margin-top: 0;
              }
            }
            .scroll-wrapper {
              height: 800px;
              &.mobile {
                display: none;
              }
              &.desktop {
                display: flex;
              }
            }
          }
        }
        &.map {
          margin-top: -7%;
        }

        .mobile {
          display: none;
        }
      }
    }
  }
}
