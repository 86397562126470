@import "../../styles/vars.scss";
.screen-article-container {
    margin: 0 auto;
    padding: 20px 10% 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1200px;
    .screen-article-back {
        cursor: pointer;
        display: flex;
        align-items: center;
        span {
            margin-left: 10px;
            font-family: $fonts-light;
        }
    }
    .screen-article-header {
        margin-top: 20px;
        span.category {
            color: #fe6831;
            font-family: $fonts-medium;
        }
        h1 {
            text-align: left;
            margin: 0 0 20px;
        }
    }
    .screen-article-share {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: #f4f4f4;
        border-radius: 6px;
        padding: 8px;
        > span {
            font-size: 14px;
        }
        .icons-container {
            display: flex;
            align-items: center;
            button {
                width: 35px;
                height: 35px;
                margin-left: 8px;
                &:active {
                    transform: scale(0.95);
                }
            }
        }
    }
    .screen-article-main-photo {
        margin: 20px 0 10px;
        width: 100%;
        img {
            cursor: pointer;
            width: 100%;
            @include bottomShadow;
            border-radius: 6px;
        }
    }
    .screen-article-gallery {
        display: flex;
        align-items: flex-end;
        width: 100%;
        overflow: auto;
        img {
            cursor: pointer;
            width: 35%;
            object-fit: cover;
            @include bottomShadow;
            border-radius: 6px;
            margin-right: 16px;
        }
    }
    .screen-article-body {
        text-align: left;
        img {
            max-width: 100%;
        }
        blockquote {
            background-color: #f4f4f4;
            margin: 0;
            padding: 30px 20px 30px 15px;
            position: relative;
            border-left: 5px solid #fe6831;
            font-family: $fonts-bold;
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 5px;
                width: 60px;
                background-color: #fe6831;
            }
            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 5px;
                width: 60px;
                background-color: #fe6831;
            }
        }
    }
}
