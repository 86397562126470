@import "../../../styles/vars.scss";

.screen-section-wrapper.hero {
  height: 80vh;
  background-image: url("../../../assets/images/hero-section-background.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  object-fit: cover;

  .mobile {
    display: none;
  }

  .text-section-wrapper {
    width: 55%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-wrapper {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0 20px 20px 0;
    padding: 40px 45px;
    padding-left: 3vw;
    // height: 30vh;
    height: fit-content;

    h1,
    p {
      text-align: left;
    }

    .buttons-wrapper {
      // width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .primary-button {
        @include primaryBttn;
        width: 200px;
        @include sectionShadow;
        margin-left: 0;
      }
      .secondary-button {
        @include secondaryBttn;
        width: 200px;
        position: relative;

        img {
          position: absolute;
          right: 7px;
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .screen-section-wrapper.hero {
    padding: 0;
    height: 68vh;
    .card-wrapper {
      padding: 20px 30px;
    }
  }
}

//zoom - small desktop size
@media screen and (max-width: 1100px) and (min-height: 1200px) {
  .screen-section-wrapper.hero {
    height: 28vh;

    .card-wrapper {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 20px;
    }

    .text-section-wrapper {
      margin-bottom: 35px;
    }


    .buttons-wrapper {

      button {
        font-size: 12px;
      }
    }
  }
}

//1350
@media screen and (max-width: 1000px) {
  .screen-section-wrapper.hero {
    min-height: fit-content;
    height: 59.8vh;
    width: 100vw;
    min-width: -webkit-fill-available;
    background-image: none;
    padding-left: 0;
    padding-right: 0;

    .card-wrapper {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 20px;
    }

    .text-section-wrapper {
      flex-direction: column;
      justify-content: center;
      margin: 0;
      margin-bottom: 35px;
      overflow-x: visible;
    }

    h1 {
      font-size: 18px;
      font-family: $fonts-regular;
    }

    p {
      font-size: 16px;
      font-family: $fonts-regular;
    }

    .mobile {
      display: flex;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .text-section-wrapper {
      width: 100%;
      text-align: center;
      align-self: center;
    }

    .buttons-wrapper {
      flex-direction: column;

      button {
        min-width: 100%;
        font-size: 14px;
        font-family: $fonts-bold;
      }
    }
  }
}
