@font-face {
    font-family: 'thin';
    src: url('../assets/fonts/CorsaGrotesk-Thin.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'light';
    src: url('../assets/fonts/CorsaGrotesk-Light.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'regular';
    src: url('../assets/fonts/CorsaGrotesk-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'medium';
    src: url('../assets/fonts/CorsaGrotesk-Medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'bold';
    src: url('../assets/fonts/CorsaGrotesk-Bold.ttf') format('truetype');
    font-display: swap;
}


