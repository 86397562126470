@import "../../styles/vars.scss";
.image-gallery-container {
    height: 100vh;
    width: 100vw;

    .image-main-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80%;
        img {
            height: 80%;
            width: 70%;
            object-fit: contain;
        }
        .icon-close {
            position: absolute;
            top: 0;
            left: 0;
            height: 80px;
            width: 80px;
            cursor: pointer;
        }
        .icon-arrow-left,
        .icon-arrow-right {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 20vw;
            min-height: 130px;
            max-height: 180px;
            width: 15%;
            cursor: pointer;
            padding: 40px 0;
        }
        .icon-arrow-left {
            left: 0;
        }
        .icon-arrow-right {
            right: 0;
            transform: rotate(180deg) translateY(50%);
        }
    }
    .image-gallery-row {
        display: flex;
        align-items: flex-end;
        height: 20%;
        overflow: auto;
        img {
            height: 100%;
            object-fit: cover;
            cursor: pointer;
        }
    }
}
