@import "../../../styles/vars.scss";

.offer-inputs-wrapper.users-invoice {
  .orange {
    color: $main-orange;
  }

  .section-wrapper {
    .section-content {
      &.wrap {
        flex-wrap: wrap;
      }
    }

    .file-drop {
      width: 100%;

      .file-drop-target {
        // transform: scale(0.95);
        transition: 0.5s;
        width: 100%;
      }
  
      .file-drop-dragging-over-target {
        opacity: 0.7;
        transform: scale(0.98);
        transition: 0.5s;
      }
    }

    .input-wrapper {
      &.upload {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 1px dashed $grey;
        transition: 0.5s;

        // &:hover {
        //   transform: scale(0.95);
        //   transition: 0.5s;
        // }

        input {
          display: none;
          min-width: 100%;
        }

        img {
          position: absolute;
          left: 1%;
          bottom: 20%;
          width: 45px;
          height: auto;
        }

        .file-drop-target {
          display: flex;
          justify-content: flex-end;
        }

        .placeholder {
          @include standardMediumText;
          text-transform: none;
          margin-right: 20px;
        }

        .grey {
          @include inputLabel;
          color: $grey;
          margin-right: 10px;
        }
      }
    }
  }

  .flex {
    &.half {
      width: 51.5%;
    }
    &.quarter {
      width: 25%;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;

    &.main {
      width: 100%;
      gap: 10px;
    }

    &.image {
      justify-content: flex-start;
      background-color: $light-orange;
      padding: 5px 10px;
      border: 1px solid #00237c3b;
      @include basicShadow;
      border-radius: 5px;

      p {
        @include standardMediumText;
        margin: 7px 0;
        margin-right: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 220px;
      }

      .delete-icon,
      img {
        cursor: pointer;
      }
    }
  }
}


//1350
@media screen and (max-width: 1000px) {
  .offer-inputs-wrapper.users-invoice {

    .section-wrapper {
      .desktop {
        display: none;
      }

      .section-content.no-margin {

        .flex {
          flex-direction: column;

          .input-wrapper.radio,
          .radio-label {
            margin-right: 0;
          }

          .primary-button {
            width: 100%;
          }
        }
      }

      .file-drop {
        .input-wrapper.text-icon.medium {
          min-width: 100%;
          margin-right: 0;
        }
      }
    }

    .section-content {
      .flex.start {
        .flex.half {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .input-wrapper {
            width: 100%;

          }
        }
      }
    }

    .flex {
      &.half,
      &.quarter {
        width: 100%;

        .input-wrapper {
          margin-right: 0;
        }
      }
    }
  }
}
