@import "../../styles/vars.scss";

.screen-wrapper.resources {
  // height: 72.198vh;
  // height: 103.198vh;
  height: fit-content;

  .screen-inner-wrapper {
    padding: 0 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0 80px 0;

    .options-wrapper {
      // width: 50%;
      display: flex;
      justify-content: space-between;
      border: 1px solid $grey;
      border-radius: 6px;
      text-align: center;
      list-style-type: none;
      gap: 20px;
      padding: 0;

      .option {
        @include regularLargeHeading;
        font-size: 20px;
        padding: 10px 15px;
        color: $grey;
        text-transform: uppercase;
        width: 350px;
        flex-wrap: nowrap;
        white-space: nowrap;
        text-align: center;
        transition: 0.5s;
        cursor: pointer;

        &.active {
          background-color: $main-orange;
          color: $white;
          font-family: $fonts-bold;
          transition: 0.5s;
        }
      }
    }

    .main-section-wrapper {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1350px) {
  .screen-wrapper.resources {
    .screen-inner-wrapper {
      padding: 0 20px;
      .options-wrapper {
        width: 100%;
        .option {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .screen-wrapper.resources {
    .screen-inner-wrapper {
      .options-wrapper {
        display: block;
        .option{
            padding: 15px;
        }
      }
    }
  }
}

@media screen and (min-width: 2200px) {
  .screen-wrapper.resources {
    .screen-inner-wrapper {
      // height: calc(100vh - 616px);
      height: calc(100vh - 400px);
    }
  }
}
