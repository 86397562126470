@import "../../styles/vars.scss";

.banner-wrapper {
    background-color: $main-orange;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url('../../assets/images/banner-base.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 0 2vw;
    @include bottomShadow;
    margin: 15px 0;
    height: 200px;

    .section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.left {
            align-items: flex-start;
            color: $white;
            text-align: left;

            h2 {
                margin-bottom: 0;
                font-size: 36px;
                font-family: $fonts-bold;
                text-transform: uppercase;
            }

            p {
                margin-top: 10px;
                @include regularHeading;
                color: $white;
                font-size: 16px;
                // font-size: 20px;
            }
        }

        &.right {
            .primary-button {
                @include primaryBttn;
                background-color: $white;
                color: black;
                @include sectionShadow;
            }
        }
    }
}

//zoom - small desktop size; tablet version 1024x768 1024x1366
@media screen and (max-width: 1200px) {
    .banner-wrapper {
        height: 200px;
    
        .section {
    
            &.right {
                .primary-button {
                    font-size: 12px;
                    white-space: nowrap;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .banner-wrapper {
        position: relative;
        flex-direction: column;
        padding: 25px 20px;
        height: fit-content;

        .section.left {
            margin-bottom: 20px;

            h2 {
                font-size: 20px;
                margin-top: 0;
            }
        }

        .section.right {
            width: 100%;

            .primary-button {
                width: 100%;
            }
        }
    }
}