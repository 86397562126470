@import "../../../styles/vars.scss";

.screen-section-wrapper.about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2vw;
  height: 80vh;

  .section-wrapper {
    &.left {
      width: 55%;

      img {
        width: 100%;
      }
    }

    &.right {
      text-align: left;
      width: 45%;

      .title-wrapper {
        h1 {
          color: $light-grey;
          font-size: 60px;
          text-transform: uppercase;
          font-size: $fonts-bold;
          font-weight: 900;
          margin-bottom: 0;
          margin-top: 10px;
          margin-left: 0;
        }

        .flex {
          display: flex;
          align-items: center;

          h2 {
            @include mediumHeading;
            color: $main-orange;
            margin-top: 10px;
            margin-bottom: 15px;
            padding-left: 10px;
          }

          .line {
            width: 7px;
            height: 30px;
            background-color: $main-orange;
            border-radius: 5px;
          }
        }
      }

      .text-wrapper {
        @include regularHeading;
        margin-bottom: 60px;
      }

      .primary-button {
        @include primaryBttn;
        @include sectionShadow;
        margin-left: 0;
      }
    }
  }
}

//zoom - small desktop size; tablet version 1024x768 1024x1366
@media screen and (max-width: 1200px) {
  .screen-section-wrapper.about {
    height: unset;
  }
}

//1350
@media screen and (max-width: 1000px) {
  .screen-section-wrapper.about {
    flex-direction: column;
    min-height: fit-content;
    height: 100vh;
    width: 100vw;
    padding: 0 20px;

    .section-wrapper {
      &.left {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%; /* Full width on mobile */
          height: auto; /* Maintain aspect ratio */
        }
      }

      &.right {
        width: 100%;
        height: fit-content;

        .title-wrapper {
          h1 {
            font-size: 26px;
          }

          .flex {
            h2 {
              font-size: 16px;
            }
          }
        }

        p {
          font-size: 16px;
        }

        .text-wrapper {
          margin-bottom: 40px;
        }

        .primary-button {
          width: 100%;
          margin: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
}
