@import "./styles/vars.scss";

html {
  box-sizing: border-box;
}

// body {
//   min-height: 100vh;
// }

.App:not(.article) {
  text-align: center;
  box-sizing: border-box;
  @include scrollbar;
  color: $black-text;
  font-family: $fonts-regular;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.screen-wrapper {
  height: fit-content;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: flex-start;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-size: 30px;
  font-family: $fonts-bold;
}

p {
  font-size: 18px;
  font-family: $fonts-regular;
}

* {
  box-sizing: border-box;
}

@media screen and (max-width: 1350px) {
  // body {
  //   width: 100vw;
  // }

  .screen-wrapper {
    all: initial;
    position: relative;
    display: flex;
    flex-direction: column;
    // width: 100vw;
  }

  html {
    // min-width: 100vw;
    // position: absolute;
    display: block;
    // min-width: -webkit-fill-available;
    box-sizing: border-box;
  }

  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }

  button {
    height: 47px;
  }
}

@media screen and (max-width: 1000px) and (max-width: 1200px) {
  button,
  .primary-bttn,
  .secondary-bttn {
    font-size: 12px !important;
  }
}
