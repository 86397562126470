@import '../../styles/vars.scss';

.screen-wrapper.home {
    height: fit-content;
    // height: calc(100vh - calc(100vh - 100%));
    // width: calc(100vw - calc(100vw - 100%));
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: flex-start;
}

@media screen and (max-width: 1350px) {
    .screen-section-wrapper {
        height: fit-content;
        min-height: fit-content;
        max-height: fit-content;
        margin-bottom: 30px;
        position: relative;
        padding: 0 20px;
    }
}