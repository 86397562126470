@import "../../styles/vars.scss";

.footer-wrapper {
  color: $white;
  margin-top: 60px;
  align-self: flex-end;
  height: 85px;
  .footer-inner-wrapper {
    background-color: $dark-blue;
    height: 100%;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    &.border {
      border-bottom: 1px solid $white;
    }

    &.bottom {
      padding: 0 40px;
      border: none;
      background-color: $dark-blue;
      &.hidden {
        display: none;
      }
      .large {
        font-size: 16px;
        font-family: $fonts-regular;
        cursor: pointer;
      }
    }

    .item {
      display: flex;
      justify-content: center;
      padding: 5px 15px;
      margin: 10px 0;
      height: 40px;
      flex: 1;

      &.border {
        border-right: 1px solid $white;
      }
      .flex-col {
        display: flex;
        flex-direction: column;

        p {
          margin: 0;
          margin-left: 10px;
          text-align: left;
          @include standardBoldText;
          font-size: 14px;
          color: $white;
          text-transform: uppercase;
        }

        .light-text {
          text-transform: none;
          font-family: $fonts-regular;
        }
      }

      .icon {
        width: 40px;
        height: 40px;
      }
    }
  }

  .footerResource {
    color: inherit;
    text-decoration: none;
  }
}
.footer-label {
  margin: 0px 5px;
  font-weight: bold !important;
  font-size: 16px;
}
@media screen and (max-width: 1450px) {
  .footer-wrapper {
    .row {
      &.border {
        border-bottom: 1px solid $white;
      }
      &.bottom {
        .large {
          font-size: 14px;
        }
      }

      .item {
        .flex-col {
          h5 {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .footer-wrapper {
    min-height: fit-content;
    min-height: 300px !important;
    height: unset;
    .footer-inner-wrapper {
      padding: 0 20px;

      .row {
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        .border {
          border: none;
        }
        &.bottom {
          text-align: left;
        }
        &.top {
          .item {
            padding-left: 0;
          }
          .icon {
            width: 26px;
            height: 26px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .footer-wrapper {
    .footer-inner-wrapper {
      .row {
        &.bottom {
          a {
            .large {
              padding-right: 8px;
            }
          }
        }
      }
    }
  }
}
