@import "../../../styles/vars.scss";

.screen-wrapper.offers-initial {
  .screen-inner-wrapper {
    margin-top: 80px;
    height: calc(100vh - 300px);
    overflow-y: hidden;

    .mobile {
      display: none;
    }

    p {
      font-size: 20px;
      font-family: $fonts-regular;
      margin-top: 40px;

      .link {
        text-decoration: underline;
        color: $main-orange;
        font-family: $fonts-bold;
        cursor: pointer;
      }
    }

    .main-section {
      &.top {
        display: flex;
        align-items: flex-start;
        margin-bottom: 40px;
        margin: 0 2vw;

        .section-wrapper {
          .title-wrapper {
            text-align: left;

            h1 {
              color: $light-grey;
              font-size: 60px;
              text-transform: uppercase;
              font-size: $fonts-bold;
              font-weight: 900;
              margin-bottom: 0;
              margin-top: 10px;
              margin-left: 0;
            }

            .flex {
              display: flex;
              align-items: center;

              h2 {
                @include mediumHeading;
                color: $main-orange;
                margin-top: 10px;
                margin-bottom: 15px;
                padding-left: 10px;
              }

              .line {
                width: 7px;
                height: 30px;
                background-color: $main-orange;
                border-radius: 5px;
              }
            }
          }
        }
      }

      &.center {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        padding: 0 2vw;
        margin-top: 30px;

        .section-wrapper {
          &.card {
            @include sectionBase;
            flex: 1;
            padding: 20px 25px;
            height: 30vh;
            cursor: pointer;

            h2 {
              @include mediumHeading;
              text-transform: uppercase;
              margin-top: 20px;
              margin-bottom: 20px;
            }

            img {
              width: 135px;
              // height: 115px;
            }
          }

          p {
            font-size: 30px;
            font-family: $fonts-regular;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .screen-wrapper.offers-initial {
    height: calc(100vh - 163px);

    .screen-inner-wrapper {
      height: 100%;
      margin-top: 0;
      padding-top: 80px;

      .main-section.center {
        .section-wrapper.card {
          height: unset;
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .screen-wrapper.offers-initial {
    height: fit-content;
    .screen-inner-wrapper {
      height: 100%;
      min-height: calc(96vh - 250px);

      .main-section {

        &.center {

          .section-wrapper {
            &.card {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      .desktop {
        text-align: center;
      }
    }
  }
}

//zoom - small desktop size
@media screen and (max-width: 1100px) and (min-height: 500px) and (max-height: 700px) {
  .screen-wrapper.offers-initial {
    .screen-inner-wrapper {
      height: fit-content;
      padding-top: 40px;
  
      .mobile {
        display: none;
      }
  
      p {
        font-size: 16px;
      }
  
      .main-section {
  
        &.center {
          .section-wrapper {
            p {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

//1350
@media screen and (max-width: 1000px) {
  .screen-wrapper.offers-initial {
    height: fit-content;
    .screen-inner-wrapper {
      height: 100%;
      overflow-x: hidden;
      margin-top: 10px;

      p {
        font-size: 16px;
        margin: 0;

        &.desktop {
          display: none;
        }
        &.mobile {
          display: block;
          padding: 0 20px;
          margin-bottom: 0;
        }
        &.padding {
          padding: 0 20px;
        }
        &.margin {
          margin-top: 15px;
        }

        .link {
          text-decoration: underline;
          color: $main-orange;
          font-family: $fonts-bold;
          cursor: pointer;
        }
      }

      .main-section {
        width: 100%;
        min-height: fit-content;

        &.top {
          padding: 0 20px;
          margin-bottom: 10px;

          .section-wrapper {
            .title-wrapper {
              text-align: left;

              h1 {
                font-size: 26px;
                font-family: $fonts-bold;
              }

              .flex {
                h2 {
                  font-size: 16px;
                  font-family: $fonts-regular;
                }

                .line {
                  min-height: 100%;
                }
              }
            }
          }
        }

        &.center {
          flex-direction: column;
          margin-top: 10px;
          padding: 0 20px;

          .section-wrapper {
            &.card {
              // padding: 10px 15px;
              height: fit-content;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;

              h2 {
                font-size: 18px;
                margin-top: 5px;
                margin-bottom: 10px;
              }

              img {
                width: 135px;
                // height: 115px;
              }
            }

            p {
              font-size: 18px;
              font-family: $fonts-regular;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .screen-wrapper.offers-initial {
    .screen-inner-wrapper {
      padding-top: 0px;
    }
  }
}

@media screen and (min-width: 2200px) and (max-width: 2999px) {
  .screen-wrapper.offers-initial {
    .screen-inner-wrapper {
      height: calc(100vh - 376px);

      .main-section {
        &.center {
          margin-top: 100px;

          .section-wrapper {
            &.card {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 40px 35px;
              height: 450px;

              img {
                width: 175px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 3000px) {
  .screen-wrapper.offers-initial {
    .screen-inner-wrapper {
      height: calc(100vh - 400px);
    }
  }
}
