@import "../../styles/vars.scss";

.header-wrapper {
  background-color: $white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  height: 80px;
  // margin-bottom: 20px;
  z-index: 100;
  @include bottomShadow;

  .header-inner-wrapper {
    background-color: $white;
    width: 99%;
    display: flex;
    justify-content: space-between;
    position: relative;
    // margin: 0 auto;
    margin: 0;
    padding: 0 2.5vw;

    .mobile {
      display: none;
    }
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .nav-wrapper {
    display: flex;
    float: none;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    bottom: 0;
    height: 100%;
    // transform: translate(-50%, -50%);

    .border {
      border-right: 2px solid $main-orange;
    }

    .nav-option {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      @include smallestHeadingMedium;
      text-transform: uppercase;
      margin: 5px 25px;
      margin-bottom: 0;
      // padding: 5px 0px;
      padding: 0px;
      //   width: 130px;

      .dropdown {
        font-size: 16px;

        &.active {
          border-bottom: none;
        }
      }

      &.active {
        font-family: $fonts-bold;
        border-bottom: 5px solid $main-orange;
      }

      &.dropdown {
        display: block;
        position: relative;

        .option-img-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .right-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .divider {
      background-color: $main-orange;
      width: 2px;
      height: 50%;
      align-self: center;
      border-radius: 10px;
      margin-right: 21px;
    }

    .margin {
      margin-right: 15px;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .border {
        border-left: 2px solid $main-orange;
      }

      .primary-bttn {
        @include primaryBttn;
      }
    }

    .languages-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 25px;
      border: solid 2px white;
      border-radius: 7px;

      .lang {
        padding: 0 5px;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        @include smallestHeading;
        font-family: $fonts-light;

        &.active {
          background-color: white;
          color: black;
          @include smallestHeadingBold;
        }
      }

      .line {
        color: black;
      }
    }
  }

  .user-wrapper {
    display: none;

    &.dropdown {
      display: flex;
    }

    > img {
      cursor: pointer;
    }

    .dropdown-wrapper {
      z-index: 10;
      width: calc(20vw + 100px);
    }
  }
}

@media screen and (max-width: 1550px) {
  .header-wrapper {

    .logo-wrapper {
      width: 20%;
      img {
        width: 90%;
      }
    }
  
    .nav-wrapper {
      .nav-option {
        margin: 5px 15px;
        margin: 5px 10px;
      }
    }
  }
}

@media screen and (max-width: 1100px) and (max-height: 1000px) {
  .header-wrapper {
  
    .logo-wrapper {
      width: 23%;
    }
  
    .nav-wrapper {
      .nav-option {
        margin: 5px 15px;
        font-size: 10px;
      }
    }

    .right-wrapper {
      .flex {
        .primary-bttn {
          font-size: 10px;
          white-space: nowrap;
          padding: 0.6em 2em;
        }
      }

      .languages-container {
        .lang, .active, .lang.active {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .header-wrapper {
  
    .nav-wrapper {
      .nav-option {
        margin: 5px 15px;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .header-wrapper {
  
    .logo-wrapper {
      width: 23%;
    }
  
    .nav-wrapper {
      .nav-option {
        margin: 5px 15px;
        font-size: 10px;
      }
    }

    .right-wrapper {
      .flex {
        .primary-bttn {
          font-size: 10px;
          white-space: nowrap;
          padding: 0.6em 2em;
        }
      }

      .languages-container {
        .lang, .active, .lang.active {
          font-size: 12px;
        }
      }
    }
  }
}

// @media screen and (min-width: 1100px) and (max-width: 1700px) {
@media screen and (max-width: 1000px) {
  .header-wrapper {
    width: 100%;
    box-shadow: none;
    .header-inner-wrapper {
      // width: 100vw;
      padding: 0;
      margin: 0;
      position: sticky;
      @include bottomShadow;

      .mobile {
        width: 100%;
        min-width: 100vw;
        cursor: pointer;
        display: block;
        @include flex;
        // top: -45vh;
        // top: -41vh;
        z-index: 100;

        .background-wrapper {
          min-width: 100vw;
          height: 100vh;
          flex-grow: 1;
          background-color: rgba(255, 255, 255, 0.58);
          position: absolute;
          top: 0;
          bottom: 0;
          z-index: 1;
        }

        .nav {
          // margin: 30px; 
          border-radius: 8px;
          width: 100%;
          height: 42px;
          // width: 40px;
          @include flex;
          justify-content: center;

          &.opened {
            all: initial;
            background-color: $white;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            @include flex;
            flex-direction: column;
            min-width: 100vw;
            padding: 10px 0;
            height: 522px;
            @include bottomShadow;
            z-index: 100;
            // top: 490px;

            .top-wrapper {
              z-index: 100;

              img {
                cursor: pointer;
                height: 60px;
                // width: 100%;
              }
            }

            .opened-nav {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              width: 90%;
              height: 100%;

              li {
                list-style: none;
                @include smallHeading;
                font-size: 18px;
                margin-bottom: 20px;
                padding-bottom: 10px;
                cursor: pointer;
                text-align: center;
                text-transform: uppercase;
              }

              .option.active {
                border-bottom: 5px solid $main-orange;
                font-family: $fonts-bold;
              }

              .languages-container {
                display: flex;
                align-items: center;

                .lang {
                  font-size: 18px;
                  font-family: $fonts-regular;

                  &.active {
                    font-family: $fonts-bold;
                  }
                }

                .line {
                  margin: 0 7px;
                }
              }

              button {
                @include primaryBttn;
                width: 100%;
                @include smallHeadingMobile;
                font-size: 14px;
                color: $white;
                border-radius: 6px;
                margin: 10px 0;
              }
            }
          }

          .top-wrapper {
            @include flex;
            width: 100%;

            .logo-wrapper {
              width: 100%;
              padding: 0;
              img {
                padding: 10px;
              }
            }
          }

          .mobile-menu-icon {
            width: 30px;
            height: 30px;
            background-image: url("../../assets/icons/menu.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin-right: 10px;
          }
        }
      }

      .nav {
        &.left {
          // width: calc(58vw - 50%);
        }

        ul li {
          padding: 5px;
        }

        .button-wrapper button {
          width: 150px;
          padding-left: 13px;
        }
      }

      .desktop {
        display: none;
      }
    }
  }
}
