@import '../../styles/vars.scss';

.radio-label {
  height: fit-content;
  margin-right: 15px;
  text-align: left;
  font-family: $fonts-regular;
  font-size: 14px;

  &.square {
    .custom-radio,
    .custom-radio::after {
      border-radius: 10%;
    }

    .custom-radio {
      width: 22px;
      height: 22px;
    }

    .custom-radio::after {
      width: 19px;
      height: 19px;
    }
  }

  label {
    height: fit-content;
  }

  .custom-radio {
    // left: -8px;
    top: 6px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    border: 1px solid #000;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-right: 5px;
  }
  
  /* for inner filled circle */
  .custom-radio::after {
    content: '';
    width: 17px;
    height: 17px;
    background: $main-orange;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-53%, -52%);
    opacity: 0;
    transition: opacity 0.2s;
  }

  .radio-input {
    display: none;
  }
  
  .radio-input:checked + span {
    border: 1px solid #000;
  }
  
  .radio-input:checked + span:after {
    opacity: 1;
  }
}

@media screen and (max-width: 1000px) {
  .radio-label {
    height: fit-content;
    margin-right: 20px;
    text-align: left;
  
    &.square {
      .custom-radio,
      .custom-radio::after {
        border-radius: 10%;
      }
    }
  
    label {
      height: fit-content;
    }
  
    .custom-radio {
      width: 30px;
      height: 30px;
    }
    
    /* for inner filled circle */
    .custom-radio::after {
      width: 25px;
      height: 25px;
      transform: translate(-50%, -50%);
    }
  }
}
