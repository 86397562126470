@import "../../../styles/vars.scss";

.screen-section-wrapper.partners {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0 2vw;
  margin: 80px 0;
  margin-bottom: 50px;

  .mobile {
    display: none;
  }

  .section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &.text {
      width: 50%;
      text-align: left;
      align-self: flex-start;

      .title-wrapper {
        h1 {
          color: $light-grey;
          font-size: 60px;
          text-transform: uppercase;
          font-size: $fonts-bold;
          font-weight: 900;
          margin-bottom: 0;
          margin-top: 10px;
          margin-left: 0;
        }

        .flex {
          display: flex;
          align-items: center;

          h2 {
            @include mediumHeading;
            color: $main-orange;
            margin-top: 10px;
            margin-bottom: 15px;
            padding-left: 10px;
          }

          .line {
            width: 7px;
            height: 30px;
            background-color: $main-orange;
            border-radius: 5px;
          }
        }
      }
    }
  }

  .slick-slider.slick-initialized {
    width: 90vw;
    height: fit-content;
    display: block;
  }

  .card-wrapper {
    all: initial;
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    @include sectionBase;
    @include sectionShadow;
    // width: 40vw;
    // min-width: 450px;
    // max-width: 450px;
    // height: 30vw;

    .top {
      display: flex;
      justify-content: flex-start;

      img {
        width: 70px;
        height: 70px;
        margin-right: 10px;
      }

      h4 {
        font-size: 24px;
        font-family: $fonts-bold;
        text-transform: uppercase;
        color: $black-text;
        margin: 0;
      }

      h5 {
        font-size: 22px;
        font-family: $fonts-regular;
        color: $blue-grey;
        margin: 0;
      }

      .flex {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
      }
    }

    .bottom {
      h4 {
        text-align: left;
        font-size: 24px;
        font-family: $fonts-bold;
        margin-bottom: 0;
      }

      p {
        @include regularHeading;
        text-align: left;
      }
    }
  }

  .cards-wrapper {
    // background-color: purple

    // display: flex;
    // align-items: center;
    // width: 100%;
    // height: 30vh;

    // overflow-x: scroll;

    .card-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include sectionBase;
      @include sectionShadow;
      // width: 40vw !important;
      width: calc(100% - 50px) !important;
      height: 350px;

      .top {
        display: flex;
        justify-content: flex-start;

        img {
          width: 70px;
          height: 70px;
          margin-right: 10px;
        }

        h4 {
          font-size: 24px;
          font-family: $fonts-bold;
          text-transform: uppercase;
          color: $black-text;
          margin: 0;
        }

        h5 {
          font-size: 22px;
          font-family: $fonts-regular;
          color: $blue-grey;
          margin: 0;
        }

        .flex {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
        }
      }

      .bottom {
        h4 {
          text-align: left;
          font-size: 24px;
          font-family: $fonts-bold;
          margin-bottom: 0;
        }

        p {
          @include regularHeading;
          text-align: left;
        }
      }
    }

    .card-wrapper + .card-wrapper {
      margin-left: 20px;
    }
  }

  .slick-slider {
    margin: 20px 0 10px;
    max-height: 450px;
    // overflow: hidden;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    display: block;
    overflow: hidden;
    max-height: 450px;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    top: 0;
    left: 0;
    display: block;
    // display: flex;
    max-height: 450px;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: "";
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    min-height: 1px;
  }
  [dir="rtl"] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  .slick-list h3 {
    width: 200px;
  }
  .slick-track div:nth-child(1) {
    margin-right: 10px;
  }
  .slick-arrow {
    position: absolute;
    bottom: -40px;
    left: 95%;
    // top: 50%!important;
    // transform: translate(0, -50%)!important;
    // background-color: orange;
    right: 0;
    width: 34px;
    height: 34px;
    padding: 0;
    text-indent: -9999px;
    background-repeat: no-repeat;
    background: $main-orange;
    border-radius: 50%;
    &:after {
      transform: translateX(-50%) translateX(-2px) translateY(-50%);
      transform: translateX(-63%) translateX(30%) translateY(-45%) rotate(135deg);
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
    }
    &.slick-prev {
      z-index: 2;
      margin-top: -40px;
      left: 0;
      transform: translateX(-50%) translateX(-30px);
      // transform: translate(calc(-100% - 5px), -50%)!important;
      &:after {
        border: solid white;
        border-radius: 2px;
        border-width: 0 3px 3px 0;
        padding: 5px;
        transform: translateX(-50%) translateX(15%) translateY(-45%) rotate(135deg);
      }
    }
    &.slick-next {
      z-index: 1;
      margin-top: -40px;
      left: 100%;
      // transform: translate(calc(5px), -50%)!important;
      transform: translateX(-50%) translateX(30px);
      &:after {
        border: solid white;
        border-radius: 2px;
        border-width: 0 3px 3px 0;
        padding: 5px;
        transform: translateX(-50%) translateX(-15%) translateY(-45%) rotate(-45deg);
      }
    }
    &.slick-disabled {
      cursor: default;
      background: $main-orange;
      border-radius: 50%;
      opacity: 0.5;
    }
  }
  .slides .slide {
    display: inline-block;
    padding: 10px 20px;
  }
  .slides .slide img {
    width: 200px;
  }

  .hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 100px;
    height: 100px;
    overflow: hidden;
  }
}

.partner-name {
  font-size: 24px;
  margin: 0px 0px 10px 0px;
  font-weight: bold !important;
}
.partners-info {
  color: #4d5d6e;
  font-size: 22px;
  margin: 0px;
}
.partner-name2 {
  text-align: left;
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: bold !important;
}
//tablet version 1024x1366
@media screen and (max-width: 1100px) and (min-height: 1200px) {
  .screen-section-wrapper.partners {
    // background-color: pink;

    .section-wrapper.text {
      width: 100%;
    }

    .cards-wrapper {
      .card-wrapper {
        .bottom {
          p {
            font-size: 16px;
          }
        }
      }
    }

    .slick-arrow.slick-next {
      transform: translateX(-50%) translateX(16px);
    }
    .slick-arrow.slick-prev {
      transform: translateX(-50%) translateX(-16px);
    }
  }
}

//tablet version 1024x768
@media screen and (min-width: 1000px) and (max-width: 1100px) and (min-height: 768px) and (max-height: 1199px) {
  .screen-section-wrapper.partners {
    .section-wrapper.text {
      width: 100%;
    }

    .cards-wrapper {
      .card-wrapper {
        .bottom {
          p {
            font-size: 16px;
          }
        }
      }
    }

    .slick-arrow.slick-next {
      transform: translateX(-50%) translateX(16px);
    }
    .slick-arrow.slick-prev {
      transform: translateX(-50%) translateX(-16px);
    }
  }
}

//zoom - small desktop size
@media screen and (max-width: 1100px) and (min-height: 500px) and (max-height: 700px) {
  .screen-section-wrapper.partners {
    .section-wrapper.text {
      width: 100%;
    }

    .cards-wrapper {
      .card-wrapper {
        .bottom {
          p {
            font-size: 16px;
          }
        }
      }
    }

    .slick-arrow.slick-next {
      transform: translateX(-50%) translateX(16px);
    }
    .slick-arrow.slick-prev {
      transform: translateX(-50%) translateX(-16px);
    }
  }
}

//1350
@media screen and (max-width: 1000px) {
  .screen-section-wrapper.partners {
    flex-direction: column;
    min-height: fit-content;
    height: unset;
    padding: 0 20px;
    margin-top: 0;

    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
      flex-direction: column;
    }

    .section-wrapper {
      &.text {
        width: 100%;

        .title-wrapper {
          h1 {
            font-size: 26px;
          }

          .flex {
            h2 {
              font-size: 16px;
            }
          }
        }

        .text-wrapper {
          margin-bottom: 0;

          p {
            font-size: 16px;
          }
        }
      }
    }

    .cards-wrapper {
      width: 100%;
      .card-wrapper {
        margin: 0;
        width: unset !important;
        height: fit-content;
        margin-bottom: 15px;
        margin-left: 0;

        .top {
          img {
            width: 30px;
            height: 30px;
          }

          .flex {
            h5 {
              font-size: 12px;
            }
          }
        }

        .bottom {
          h4 {
            font-size: 18px;
            font-family: $fonts-bold;
          }

          p {
            font-size: 16px;
          }
        }
      }

      .card-wrapper + .card-wrapper {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .screen-section-wrapper.partners {
    .cards-wrapper {
      width: 100%;
      // .card-wrapper + .card-wrapper {
      //   margin-left:0px;
      // }
    }
  }

  .partner-name {
    font-size: 20px;
  }
  .partner-info {
   font-size:18px;
   margin:0px !important;
  }
  .partner-name2 {
    font-size:18px;
  }
}
