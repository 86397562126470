@import '../../styles/vars.scss';

.upload-button-container {
    @include uploadBttn;
    border: none;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    box-shadow: none;
    position: relative;
    width: fit-content;

    &.wide {
        width: 100%;
    }

    img {
        position: absolute;
        left: 1%;
        bottom: 20%;
        width: 45px;
        height: auto;
    }

    .primary-button {
        @include primaryBttn;
    }

    .black {
        @include standardMediumText;
        text-transform: none;
        margin-right: 20px;
    }

    .grey {
        @include inputLabel;
        color: $grey;
        margin-right: 20px;
    }
}