@import "../../styles/vars.scss";

.resources-section-wrapper {
  width: 100%;
  height: fit-content;
  .content-wrapper {
    display: flex;

    .column {
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      flex: 1;
      margin-top: 50px;

      &.scroll {
        overflow-y: auto;
        @include scrollbar;
        // height: 67vh;
        height: 900px;
      }
      &.wide {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 15px;
      }

      .text {
        text-align: left;
        font-size: 20px;
        font-family: $fonts-regular;
        line-height: 27px;
        border-radius: 10px;
        border:  1px solid $main-orange;
        padding: 15px 20px;
      }

      .question-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-bottom: 10px;
        @include bottomShadow;
        border-radius: 8px;
        padding: 15px 20px;
        white-space: pre-line;
        transition: 1s;

        &.selected {
          border: 2px solid $main-orange;
          font-family: $fonts-bold;
          transition: 1s;

          .question {
            font-family: $fonts-bold;
            color: $main-orange;
          }
        }

        .question {
          font-size: 18px;
          font-family: $fonts-regular;
          color: $grey;
          text-transform: uppercase;
          text-align: left;
        }

        .arrow-icon {
          margin-left: 10px;
          transform: rotate(180deg);
          height: 26px;
        }
      }

      .link-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-bottom: 10px;
        @include bottomShadow;
        border-radius: 8px;
        padding: 15px 20px;
        white-space: pre-line;
        width: 45%;
        text-align: left;
        text-transform: uppercase;
        // height: fit-content;
        // min-height: 55px;
        font-family: $fonts-regular;
        .download-icon {
          margin-left: 10px;
          width: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .resources-section-wrapper {
    .content-wrapper {
      display: block;
      .column {
        padding: 0;

        &.scroll {
          height: unset;
          padding: 2px;
        }
        &.wide {
          flex-direction: column;
          width: 100%;
        }
        .text {
          padding: 10px 0;
          border: none;
        }
        .question-wrapper {
          .question {
            font-size: 16px;
          }
        }
        .link-wrapper {
          width: 100%;
          .link {
            font-family: $fonts-regular;
          }
        }
      }
    }
  }
}

// @media screen and (min-width: 2200px) {
//   .resources-section-wrapper {
//     .content-wrapper {
//       .column {
//         &.scroll {
//           min-height: 700px;
//         }
//       }
//     }
//   }
// }
