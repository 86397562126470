@import "../../../styles/vars.scss";

.screen-wrapper.offers-options {
  .screen-inner-wrapper {
    margin-top: 25px;
    // height: 65.7vh;
    min-height: calc(100vh - 250px);
    // height: fit-content;

    .section-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      @include sectionBase;
      padding: 15px 25px;
      display: flex;
      align-items: center;

      .flex {
        display: flex;
        align-items: center;

        &.start {
          justify-content: flex-start;
          text-align: left;
        }
        &.col {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &.top {
        justify-content: flex-start;
        flex-wrap: nowrap;
        margin-bottom: 20px;

        img {
          min-height: 100%;
          margin-right: 10px;
          height: 40px;
          cursor: pointer;
        }

        h2 {
          @include mediumHeading;
          text-transform: uppercase;
          font-size: 20px;
          margin-top: 0;
          margin-bottom: 10px;
        }

        p {
          margin: 0;
          font-size: 14px;

          .link {
            text-decoration: underline;
            color: $main-orange;
            font-family: $fonts-bold;
            cursor: pointer;
          }
        }
      }

      &.bottom {
        flex-direction: column;
        margin: 0 2vw;

        h2 {
          @include mediumHeading;
          font-family: $fonts-bold;
          text-transform: uppercase;
          font-size: 20px;
          margin-top: 20px;
          margin-bottom: 10px;
        }

        p {
          margin: 0;
          font-size: 16px;

          .link {
            text-decoration: underline;
            color: $main-orange;
            font-family: $fonts-bold;
          }
        }

        .flex.start {
          align-self: flex-start;
        }

        .cards-wrapper {
          display: flex;
          justify-content: space-between;
          margin-top: 25px;
          gap: 15px;
          align-items: stretch;
          width: 100%;

          &.start {
            justify-content: flex-start;
            align-self: flex-start;
            width: 45%;
          }

          .card {
            text-align: center;
            align-items: center;
            justify-content: flex-start;
            @include sectionBase;
            z-index: 10;
            opacity: 1;
            margin: 0;
            padding: 45px 55px;
            transition: 1s;
            cursor: pointer;
            flex: 1;

            &.start {
              align-self: flex-start;
            }
            &.end {
              align-self: flex-end;
            }
            &.margin {
              margin-bottom: 90px;
            }

            h2 {
              @include regularLargeHeading;
              font-family: $fonts-regular;
              text-transform: none;
              font-size: 18px;
              margin-bottom: 0;
            }

            p {
              font-size: 20px;
            }

            .icon {
              width: auto;
              height: 60px;
            }

            .link {
              display: flex;
              align-items: center;
              cursor: pointer;
              // @include smallHeading;
              @include tableText;
              color: $white;
            }
          }
        }
      }
    }
  }
}

//1350
@media screen and (max-width: 1000px) {
  .screen-wrapper.offers-options {
    height: fit-content;

    .screen-inner-wrapper {
      height: fit-content;
      margin-top: 0;
      padding: 0 20px;
      // height: fit-content;

      .section-wrapper {
        padding: 10px 15px;

        &.top {
          padding: 20px 15px;

          h2 {
            font-size: 18px;
          }
        }

        &.bottom {
          margin: 0;
          padding: 20px 15px;

          h2 {
            margin-top: 0;
          }

          .cards-wrapper {
            flex-direction: column;
            margin-bottom: 20px;

            &.start {
              width: 100%;
            }

            .card {
              width: 100%;
              flex: 1;

              &.start {
                align-self: flex-start;
              }
              &.end {
                align-self: flex-end;
              }
              &.margin {
                margin-bottom: 90px;
              }

              h2 {
                @include regularLargeHeading;
                font-family: $fonts-regular;
                text-transform: none;
                font-size: 18px;
                margin-bottom: 0;
              }

              p {
                font-size: 20px;
              }

              .icon {
                width: auto;
                height: 60px;
              }

              .link {
                display: flex;
                align-items: center;
                cursor: pointer;
                // @include smallHeading;
                @include tableText;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .screen-wrapper.offers-options {
    .screen-inner-wrapper {
      // margin-top: 25px;
      min-height: calc(100vh - 250px);
    }
  }
}

@media screen and (min-width: 2200px) {
  .screen-wrapper.offers-options {
    .screen-inner-wrapper {
      min-height: calc(100vh - 326px);
    }
  }
}
