@import '../../styles/vars.scss';

.screen-wrapper.users {
    // padding: 0 2vw;

    .screen-inner-wrapper {
        width: 100%;
        .screen-header-wrapper {
            padding: 0 2vw;
            width: 100%;
            background-image: url('../../assets/images/users-section.webp');
            background-position: center top;
            background-repeat: no-repeat;
            background-size: 100%;
            
            .flex {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                // padding-top: 10px;

                &.padding {
                    padding-top: 20px;
                }

                span {
                    @include regularHeading;
                    cursor: pointer;
                }

                p {
                    padding-top: 0;
                }

                img {
                    cursor: pointer;
                    margin-right: 10px;
                }
            }
        }

        .screen-main-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin-bottom: 70px;
            gap: 15px;
            &:not(.mobile) {
                margin: 0 2vw 70px;
            }
            &.mobile {
                display: none;
            }
        }

        .section-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;

            .flex {
                display: flex;
                align-items: center;

                &.col {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    gap: 10px;
                    transition: 0.5s;
                }
                &.hidden {
                    display: none;
                    transition: 1s;
                }
                &:hovered {
                    display: flex;
                    justify-content: center;
                    transition: 1s;
                }

                h5 {
                    font-size: 18px;
                    font-family: $fonts-light;
                    margin: 0;
                    text-align: left;
                }

                .line {
                    min-width: 7px;
                    height: 30px;
                    background-color: $black-text;
                    border-radius: 5px;
                    margin-right: 10px;
    
                    &.orange {
                        background-color: $main-orange;
                    }
                }
            }

            .primary-bttn {
                @include primaryBttn;
                margin-left: 0;
                margin-top: 20px;
            }
    
            &.text {
                text-align: left;
                align-self: flex-start;
    
                .title-wrapper {
    
                    h1 {
                        color: $black-text;
                        font-size: 60px;
                        text-transform: uppercase;
                        font-size: $fonts-bold;
                        font-weight: 900;
                        margin-bottom: 0;
                        margin-top: 10px;
                        margin-left: 0;
                    }
    
                    .flex {
                        display: flex;
                        align-items: center;

                        &.col {
                            flex-direction: column;
                            z-index: 100;
                        }
    
                        h2 {
                            @include mediumHeading;
                            color: $black-text;
                            margin-top: 10px;
                            margin-bottom: 15px;
                            padding-left: 10px;
                        }
                    }
                }
    
                .text-wrapper {
                    @include regularHeading;
                    margin-bottom: 60px;
                }
            }

            &.card {
                text-align: center;
                align-items: center;
                justify-content: center;
                width: 40%;
                @include sectionBase;
                box-shadow: none;
                border: 1px solid rgba(195, 191, 191, 0.562);
                z-index: 10;
                opacity: 1;
                margin: 0;
                padding: 45px 55px;
                height: 420px;
                transition: 1s; 
                flex: 1 0 40%;
                gap: 40px;
                &.start {
                    align-self: flex-start;
                }
                &.end {
                    align-self: flex-end;
                }
                &.margin {
                    margin-bottom: 90px;
                }
                &.hovered {
                    display: flex;
                    justify-content: center;
                    transition: 1s;
                }
                &.hidden {
                    h2, p, img {
                        display: none;
                        transition: 1s; 
                    }
                }
                &:hover {
                    transition: 1s;
                    img, h2, p {
                        display: hidden;
                    }
                }
    
                h2 {
                    @include regularLargeHeading;
                    font-family: $fonts-bold;
                    color: $main-orange;
                    margin-bottom: 0;
                    text-transform: uppercase;
                }
    
                p {
                    color: $black-text;
                }
    
                .icon {
                    width: 66px;
                    height: auto;
                }
    
                .link {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    // @include smallHeading;
                    @include tableText;
                    color: $main-orange;
                }
            }
        }
    }
}

@media screen and (min-width: 2100px) {
    .screen-wrapper.users {
        .screen-inner-wrapper {
            .screen-main-wrapper {
                margin-top: 30px;
            }

            .section-wrapper {
    
                .flex {
                    width: 100%;
    
                    &.col {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1350px) {
    .screen-wrapper.users {

        .screen-inner-wrapper {

            .screen-header-wrapper {
                background-image: none;
                padding: 0;
                padding: 0 20px;

                span {
                    font-size: 16px;
                }

                .section-wrapper {

                    &.text {
                        .title-wrapper {
                            h1 {
                                font-size: 26px;
                                color: $light-grey;
                            }

                            h2 {
                                color: $main-orange;
                                font-size: 18px;
                            }

                            .line {
                                background-color: $main-orange;
                            }
                        }
                    }
                }

            }

            .screen-main-wrapper {
                flex-direction: column;
                padding: 0 20px;

                &.desktop {
                    display: none;
                }
                &.mobile {
                    display: flex;

                    .section-wrapper.card {
                        width: 100%;
                        align-items: flex-start;
                        text-align: left;
                        padding: 20px 25px;
                        height: unset;

                        h2 {
                            font-size: 18px;
                            font-family: $fonts-bold;
                        }

                        p {
                            font-size: 16px;
                            margin-bottom: 25px;
                        }

                        h5 {
                            all: initial;
                            font-size: 16px;
                            font-family: $fonts-light;
                        }

                        button {
                            width: 100%;
                            font-size: 14px;
                            font-family: $fonts-bold;
                        }

                        .icon {
                            width: 54px;
                            height: 54px;
                        }

                        .line {
                            min-width: 4px;
                        }
                    }
                }
            }
        }
    }
}