@import "../../../styles/vars.scss";

@media screen and (max-width: 1000px) {
  .offer-inputs-wrapper.users-invoice {
    .section-wrapper {
      .input-wrapper.upload {
        img {
          left: 3%;
        }
      }
    }
  }

  .offer-inputs-wrapper {
    .primary-button {
      margin: 0px;
    }
  }
}

@media screen and (min-width: 2200px) {
  .screen-wrapper.offer.user {
    .screen-inner-wrapper {
      min-height: calc(100vh - 316px);
    }
  }
}
