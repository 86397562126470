@import "../../../styles/vars.scss";

.screen-section-wrapper.products {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0 2vw;
  margin: 80px 0;
  gap: 30px;

  .mobile {
    display: none;
  }

  .img-mobile {
    display: none;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 83vh;
    height: 65vh;
    background-image: url("../../../assets/images/product-section-background.webp");
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 60%;

    &.manufacturers {
      background-image: url("../../../assets/images/product-section-2.webp");
      background-position: right bottom;
      justify-content: flex-start !important;
      // align-items: center;
      height: 60vh;
      height: 47vh;

      .card {
        width: 50%;
        margin-left: 0;
      }

      .card.margin-top.desktop {
        margin-top: 0;
      }
    }

    &.services {
      background-image: url("../../../assets/images/prouct-section-3.webp");
      background-position: left bottom;
      justify-content: flex-start;
      align-items: center;
      height: 45vh;
      width: 100%;

      .card {
        width: 45%;
        margin-right: 0;
      }
    }
  }

  .section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &.mobile {
      display: none;
    }

    &.text {
      width: 50%;
      text-align: left;
      align-self: flex-start;

      .title-wrapper {
        h1 {
          color: $light-grey;
          font-size: 60px;
          text-transform: uppercase;
          font-size: $fonts-bold;
          font-weight: 900;
          margin-bottom: 0;
          margin-top: 10px;
          margin-left: 0;
        }

        .flex {
          display: flex;
          align-items: center;

          h2 {
            @include mediumHeading;
            color: $main-orange;
            margin-top: 10px;
            margin-bottom: 15px;
            padding-left: 10px;
          }

          .line {
            width: 7px;
            height: 30px;
            background-color: $main-orange;
            border-radius: 5px;
          }
        }
      }

      .text-wrapper {
        @include regularHeading;
        margin-bottom: 60px;
      }
    }

    &.card {
      text-align: left;
      width: 45%;
      @include sectionBase;
      z-index: 10;
      opacity: 1;

      &.start {
        align-self: flex-start;
      }
      &.end {
        align-self: flex-end;
      }
      &.margin {
        margin-bottom: 40px;
      }
      &.margin-top {
        margin-top: 150px;
      }

      h2 {
        @include regularLargeHeading;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      p {
        color: $black-text;
      }

      .icon {
        width: 100px;
        height: auto;
      }

      .link {
        display: flex;
        align-items: center;
        cursor: pointer;
        // @include smallHeading;
        @include tableText;
        color: $main-orange;
      }
    }
  }
}

@media screen and (min-width: 1550px) {
  .screen-section-wrapper.products {
    .row {
      height: 700px;
      width: 100%;

      .section-wrapper.card.end.margin.desktop {
        margin-right: 0;
        margin-bottom: 80px;
      }
  
      &.manufacturers {
        height: 45vh;

        .section-wrapper.card.margin-top.desktop {
          margin-left: 5px;
        }
      }
  
      &.services {
        height: 41vh;
      }
    }
  }
}

@media screen and (min-width: 2100px) {
  .screen-section-wrapper.products {
    .row {
      // height: 68vh;
      height: 900px;
      width: 100%;

      .section-wrapper.card.end.margin.desktop {
        margin-bottom: 110px;
        padding: 80px 30px;
      }

      &.manufacturers {
        height: 660px;

        .section-wrapper.card.margin-top.desktop {
          padding: 80px 30px;
        }
      }

      &.services {
        height: 660px;

        .section-wrapper.card.desktop {
          padding: 80px 30px;
        }
      }
    }
  }
}

@media screen and (min-width: 2800px) {
  .screen-section-wrapper.products {
    .row {
      min-height: 1200px;
      width: 100%;

      .section-wrapper.card.end.margin.desktop {
        margin-bottom: 165px;
        padding: 200px 60px;
      }

      &.manufacturers {
        height: 660px;

        .section-wrapper.card.margin-top.desktop {
          padding: 200px 60px;
          margin-top: 200px;
        }
      }

      &.services {
        height: 660px;

        .section-wrapper.card.desktop {
          padding: 200px 60px;
          margin-top: 200px;
        }
      }
    }
  }
}

@media screen and (max-width: 1550px) {
  .screen-section-wrapper.products {
    .row {
      height: 73vh;
      width: 100%;

      .section-wrapper.card.end.margin.desktop {
        margin-right: 0;
      }
  
      &.manufacturers {
        height: 45vh;
        background-position: right center;
        // background-size: 100%;

        .section-wrapper.card.margin-top.desktop {
          margin-left: 5px;
        }

        .section-wrapper {
          .card {
            margin-bottom: 20px;
          }
  
          .margin-top {
            margin-top: 0;
          }
        }
      }
  
      &.services {
        background-image: url("../../../assets/images/prouct-section-3.webp");
        background-position: left bottom;
        justify-content: flex-start;
        align-items: center;
        height: 41vh;
        width: 100%;
        // margin-top: 25px;
  
        .card {
          width: 45%;
          margin-top: 0;
        }
      }
    }
  
    .section-wrapper {
  
      &.text {
        width: 50%;
        text-align: left;
        align-self: flex-start;
  
        .title-wrapper {
          h1 {
            white-space: nowrap;
          }
  
          .flex {
            display: flex;
            align-items: center;
  
            h2 {
              @include mediumHeading;
              color: $main-orange;
              margin-top: 10px;
              margin-bottom: 15px;
              padding-left: 10px;
            }
  
            .line {
              width: 7px;
              height: 30px;
              background-color: $main-orange;
              border-radius: 5px;
            }
          }
        }
  
        .text-wrapper {
          @include regularHeading;
          margin-bottom: 10px;
        }
      }
  
      &.card {
        text-align: left;
        width: 45%;
        @include sectionBase;
        z-index: 10;
        opacity: 1;
  
        &.start {
          align-self: flex-start;
        }
        &.end {
          align-self: flex-end;
        }
        &.margin {
          margin-bottom: 20px;
        }
        &.margin-top {
          margin-top: 10px;
        }
  
        h2 {
          @include regularLargeHeading;
          margin-bottom: 0;
          text-transform: uppercase;
        }
  
        p {
          color: $black-text;
        }
  
        .icon {
          width: 100px;
          height: auto;
        }
  
        .link {
          display: flex;
          align-items: center;
          cursor: pointer;
          // @include smallHeading;
          @include tableText;
          color: $main-orange;
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .screen-section-wrapper.products {
    .row {
      height: 77vh;
    }
  }
}

//zoom - 125%
@media screen and (min-width: 1150px) and (max-width: 1450px) and (min-height: 600px) and (max-height: 850px) {
  .screen-section-wrapper.products {
    height: fit-content;

    .row {
      height: 98vh;

      &.manufacturers {
        height: 72vh;
      }
      &.services {
        height: 50vh;
      }

      
      .section-wrapper.card.end.margin.desktop {
        margin-bottom: 3px;
      }
    }
  }
}

// @media screen and (max-width: 1250px)
@media screen and (max-width: 1250px) {
  .screen-section-wrapper.products {
    margin-top: 10px;

    // .row {
    //   flex-direction: column;
    //   height: unset;
    //   background-image: none;
    //   width: 100%;

    //   &.manufacturers,
    //   &.services {
    //     height: unset;
    //     background-image: none;
    //   }

    //   .mobile {
    //     display: block;
    //   }

    //   .section-wrapper {
    //     width: 100%;
    //     position: relative;
    //     height: fit-content;
    //     border-radius: 5px;

    //     .img-mobile {
    //       display: block;
    //       width: 100%;
    //       height: 150px;
    //       object-fit: cover;
    //       border-radius: 5px;
    //     }

    //     &.mobile {
    //       display: flex;
    //       flex-direction: column;
    //       justify-content: flex-end;
    //       // height: calc(100% + 400px);
    //       // height: 350px;
    //     }
    //     &.desktop {
    //       display: none;
    //     }

    //     &.text {
    //       .title-wrapper {
    //         h1 {
    //           font-size: 26px;
    //         }
    //         h2 {
    //           font-size: 16px;
    //         }
    //       }

    //       .text-wrapper {
    //         margin-bottom: 20px;

    //         p {
    //           font-size: 16px;
    //           margin-top: 5px;
    //         }
    //       }
    //     }

    //     &.card {
    //         background-color: none;
    //         background-color: rgba(255,255,255, 0.8);
    //         height: unset;
    //         min-height: fit-content;
    //         width: 100%;
    //         top: -35px;

    //       h2 {
    //         font-size: 18px;
    //         font-family: $fonts-medium;
    //       }

    //       p {
    //         font-size: 16px;
    //         margin-top: 10px;
    //       }

    //       .link {

    //         span {
    //             font-size: 14px;
    //         }

    //         img {
    //             margin-top: 5px;
    //             width: 45px;
    //             height: 45px;
    //         }
    //       }

    //       .icon {
    //         width: 35px;
    //         height: 35px;
    //       }
    //     }
    //   }
    // }
  }
}

//tablet version 1024x1366
@media screen and (max-width: 1100px) and (min-height: 1200px) {
  .screen-section-wrapper.products {
    // height: 50vh;
    // background-color: pink;
  
    .mobile {
      display: none;
    }
  
    .img-mobile {
      display: none;
    }

    .row {
      height: 40vh;

      &.manufacturers {
        height: 33vh;
      }
      &.services {
        height: 19vh;
      }

      .section-wrapper.card.end.margin.desktop {
        margin-bottom: -33px;
      }
    }
  }
}

//tablet version 1024x768
@media screen and (min-width: 1000px) and (max-width: 1199px) and (min-height: 768px) and (max-height: 1199px) {
  .screen-section-wrapper.products {
    // height: 50vh;
    // background-color: blueviolet;
  
    .mobile {
      display: none;
    }
  
    .img-mobile {
      display: none;
    }

    .row {
      height: 70vh;

      &.manufacturers {
        height: 50vh;
      }
      &.services {
        height: 35vh;
      }

      .section-wrapper.card.end.margin.desktop {
        margin-bottom: -33px;
      }
    }
  }
}

//zoom - small desktop size
@media screen and (max-width: 1100px) and (min-height: 500px) and (max-height: 700px) {
  .screen-section-wrapper.products {
    height: fit-content;
    // background-color: aqua;
  
    .mobile {
      display: none;
    }
  
    .img-mobile {
      display: none;
    }

    .row {
      height: 92vh;

      &.manufacturers {
        height: 72vh;
      }
      &.services {
        height: 50vh;
      }

      
      .section-wrapper.card.end.margin.desktop {
        margin-bottom: -32px;
      }
    }
  }
}

//@media screen and (max-width: 769px)
@media screen and (max-width: 1000px) {
  .screen-section-wrapper.products {
    flex-direction: column;
    min-height: fit-content;
    height: unset;
    padding: 0 20px;
    margin-top: 15px;

    .desktop {
        display: none;
    }

    .row {
      flex-direction: column;
      height: unset;
      background-image: none;
      width: 100%;

      &.manufacturers,
      &.services {
        height: unset;
        background-image: none;
      }

      .mobile {
        display: block;
      }

      .section-wrapper {
        width: 100%;
        position: relative;
        height: fit-content;
        border-radius: 5px;

        .img-mobile {
          display: block;
          width: 100%;
          height: 150px;
          object-fit: cover;
          border-radius: 5px;
        }

        &.mobile {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          // height: calc(100% + 400px);
          // height: 350px;
        }
        &.desktop {
          display: none;
        }

        &.text {
          .title-wrapper {
            h1 {
              font-size: 26px;
            }
            h2 {
              font-size: 16px;
            }
          }

          .text-wrapper {
            margin-bottom: 20px;

            p {
              font-size: 16px;
              margin-top: 5px;
            }
          }
        }

        &.card {
            background-color: none;
            background-color: rgba(255,255,255, 0.8);
            height: unset;
            min-height: fit-content;
            width: 100%;
            top: -35px;

          h2 {
            font-size: 18px;
            font-family: $fonts-medium;
          }

          p {
            font-size: 16px;
            margin-top: 10px;
          }

          .link {

            span {
                font-size: 14px;
            }

            img {
                margin-top: 5px;
                width: 45px;
                height: 45px;
            }
          }

          .icon {
            width: 35px;
            height: 35px;
          }
        }
      }
    }

    // .row {
    //   flex-direction: column;
    //   height: unset;
    //   background-image: none;
    //   width: 100%;

    //   &.manufacturers,
    //   &.services {
    //     height: unset;
    //     background-image: none;
    //   }

    //   .mobile {
    //     display: block;
    //   }

    //   .section-wrapper {
    //     width: 100%;
    //     position: relative;
    //     height: fit-content;

    //     .img-mobile {
    //       display: block;
    //       width: 100%;
    //       height: 150px;
    //       object-fit: cover;
    //     }

    //     &.mobile {
    //       display: flex;
    //       flex-direction: column;
    //       justify-content: flex-end;
    //       // height: calc(100% + 400px);
    //       // height: 350px;
    //     }
    //     &.desktop {
    //       display: none;
    //     }

    //     &.text {
    //       .title-wrapper {
    //         h1 {
    //           font-size: 26px;
    //         }
    //         h2 {
    //           font-size: 16px;
    //         }
    //       }

    //       .text-wrapper {
    //         margin-bottom: 20px;

    //         p {
    //           font-size: 16px;
    //           margin-top: 5px;
    //         }
    //       }
    //     }

    //     &.card {
    //         background-color: none;
    //         background-color: rgba(255,255,255, 0.8);
    //         height: unset;
    //         min-height: fit-content;
    //         width: 100%;
    //         top: -35px;

    //       h2 {
    //         font-size: 18px;
    //         font-family: $fonts-medium;
    //       }

    //       p {
    //         font-size: 16px;
    //         margin-top: 10px;
    //       }

    //       .link {

    //         span {
    //             font-size: 14px;
    //         }

    //         img {
    //             margin-top: 5px;
    //             width: 45px;
    //             height: 45px;
    //         }
    //       }

    //       .icon {
    //         width: 35px;
    //         height: 35px;
    //       }
    //     }
    //   }
    // }
  }
}
