@import '../../../styles/vars.scss';

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #010405e2;
    z-index: 1000;

    // left: 50%;
    // top: 30%;
    // transform: translate(-50%, 0);

    &.successful-offer-query {
      button {
        @include primaryBttn;
        font-size: 16px;
        width: 100%;
      }
    }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1000;
    background-color: $white;
    @include sectionShadow;
    border-radius: 8px;
    padding: 50px;
    left: 50%;
    top: 25%;
    transform: translate(-50%, 0);

    .top-wrapper {
      display: flex;
      flex-direction: column;

      h1 {
        @include smallHeading;
        font-size: 20px;
        font-family: $fonts-bold;
        text-transform: uppercase;
        margin: 40px 0 25px 0;
      }

      p {
        margin-top: 0;
        margin-bottom: 45px;
        @include regularHeading;
      }

      img {
        height: 15vh;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .modal-wrapper {

    .content-wrapper {
      padding: 20px;
      width: 90%;
      text-align: center;

      .top-wrapper {
        h1 {
          margin: 25px 0;
        }
  
        p {
          font-size: 16px;
          margin-bottom: 40px;
        }
      }
    }
  }
}