@import "../../../styles/vars.scss";

.info-section-container {
  &.featured .info-section-hero-slider-container .text-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .info-section-hero-slider-container {
    position: relative;

    .image-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        height: 40vw;
        width: 60vw;
      }
    }
    .text-wrapper {
      cursor: pointer;
      padding: 20px 20vw;
      background-color: #f4f4f4;
      box-shadow: 10px -5px 15px 0px rgba(0, 52, 112, 0.15);
      p.category {
        margin: 0;
        height: 22px;
        color: #fe6831;
        font-family: $fonts-medium;
      }
      h2 {
        margin: 0;
        line-height: 45px;
        font-family: $fonts-bold;
        font-size: 32px;
      }
      span.createdAt {
        font-family: $fonts-light;
        font-size: 14px;
      }
    }
    .icon {
      z-index: 2;
      height: 60px;
      width: 60px;
      padding: 10px;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &:active {
        transform: scale(0.98) translateY(-50%);
      }
    }
    .icon-arrow-left {
      left: 5vw;
    }
    .icon-arrow-right {
      right: 5vw;
    }
    .mobile-slice-container {
      display: none;
    }
  }
  .info-section-categories-container {
    margin: 40px 5vw;
    background-color: #f4f4f4;
    display: flex;
    align-items: stretch;
    border-radius: 6px;
    gap: 16px;
    overflow: auto;
    > div {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      cursor: pointer;
      font-family: $fonts-medium;
      white-space: nowrap;
      transition: 0.2s;
      &.active {
        background-color: #fe6831;
        color: white;
        font-family: $fonts-bold;
        border-radius: 6px;
      }
    }
  }
  .info-section-content {
    margin: 0 5vw 40px;
    display: flex;
    flex-direction: column;

    .single-article-container {
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: stretch;
      margin-bottom: 40px;
      > img {
        @include bottomShadow;
        border-radius: 6px;
        height: 16.6vw;
        width: 25vw;
        margin-right: 20px;
      }
      .text-wrapper {
        height: 16.6vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .article-header {
          p.category {
            margin: 0;
            height: 22px;
            color: #fe6831;
            font-family: $fonts-medium;
            font-size: 14px;
          }
          h2 {
            margin: 0;
            font-family: $fonts-bold;
          }
          span.createdAt {
            font-family: $fonts-light;
            font-size: 12px;
          }
        }
        .article-body {
          flex: 1;
          overflow: hidden;
          padding: 10px 0;
          * {
            font-size: 16px !important;
            margin: 0 !important;
            color: $black-text !important;
            font-family: $fonts-regular !important;
          }
        }
        .article-footer {
          display: flex;
          align-items: center;
          cursor: pointer;
          @include tableText;
          color: $main-orange;
          .icon {
            width: 38px;
            height: 38px;
            box-shadow: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .info-section-container {
    &.featured .info-section-hero-slider-container .text-wrapper {
      position: relative;
    }

    .info-section-hero-slider-container {
      .image-wrapper img {
        height: 49.5vw;
        width: 100vw;
      }
      .icon {
        display: none;
      }

      .text-wrapper {
        padding: 20px 40px;
        h2 {
          font-size: 18px;
          line-height: normal;
        }
      }
      .mobile-slice-container {
        display: block;
        display: flex;
        gap: 10px;
        justify-content: center;
        padding: 0 5vw;
        margin-top: 40px;
        > div {
          cursor: pointer;
          height: 15px;
          flex: 1;
          border-radius: 6px;
          background-color: #f4f4f4;
          transition: 0.2s;
          &.active {
            background-color: #fe6831;
          }
        }
      }
    }
    .info-section-content .single-article-container {
      flex-direction: column;
      > img {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
      }
      .text-wrapper {
        height: auto;
        .article-body {
          * {
            font-size: 14px !important;
          }
        }
        .article-footer {
          font-size: 14px;
        }
      }
    }
  }
}
